export default [

{
    "no": "1",
    "question": "A company plans to use an Amazon Snowball Edge device to transfer files to the AWS Cloud.\nWhich activities related to a Snowball Edge device are available to the company at no cost?",
    "1": "Use of the Snowball Edge appliance for a 10-day period",
    "2": "The transfer of data out of Amazon S3 and to the Snowball Edge appliance",
    "3": "The transfer of data from the Snowball Edge appliance into Amazon S3 ",
    "4": "Daily use of the Snowball Edge appliance after 10 days",
    "5": "1",
    "answer": "C",
    "examName": "AWS Certified Cloud Practitioner CLF-C02"
  },

{
    "no": "2",
    "question": "A company has deployed applications on Amazon EC2 instances. The company needs to assess application vulnerabilities and must identify infrastructure deployments that do not meet best practices.\nWhich AWS service can the company use to meet these requirements?",
    "1": "AWS Trusted Advisor",
    "2": "Amazon Inspector",
    "3": "AWS Config",
    "4": "Amazon GuardDuty",
    "5": "1",
    "answer": "B",
    "examName": "AWS Certified Cloud Practitioner CLF-C02"
  },
{
    "no": "3",
    "question": "A company has a centralized group of users with large file storage requirements that have exceeded the space available on premises. The company wants to extend its file storage capabilities for this group while retaining the performance benefit of sharing content locally.\nWhat is the MOST operationally efficient AWS solution for this scenario?",
    "1": "Create an Amazon S3 bucket for each user. Mount each bucket by using an S3 file system mounting utility.",
    "2": "Configure and deploy an AWS Storage Gateway file gateway. Connect each user’s workstation to the file gateway.",
    "3": "Move each user’s working environment to Amazon WorkSpaces. Set up an Amazon WorkDocs account for each user.",
    "4": "Deploy an Amazon EC2 instance and attach an Amazon Elastic Block Store (Amazon EBS) Provisioned IOPS volume. Share the EBS volume directly with the users.",
    "5": "1",
    "answer": "B",
    "examName": "AWS Certified Cloud Practitioner CLF-C02"
  },
{
    "no": "4",
    "question": "According to security best practices, how should an Amazon EC2 instance be given access to an Amazon S3 bucket?",
    "1": "Hard code an IAM user’s secret key and access key directly in the application, and upload the file.",
    "2": "Store the IAM user’s secret key and access key in a text file on the EC2 instance, read the keys, then upload the file.",
    "3": "Have the EC2 instance assume a role to obtain the privileges to upload the file.",
    "4": "Modify the S3 bucket policy so that any service can upload to it at any time.",
    "5": "1",
    "answer": "C",
    "examName": "AWS Certified Cloud Practitioner CLF-C02"
  },
{
    "no": "5",
    "question": "Which option is a customer responsibility when using Amazon DynamoDB under the AWS Shared Responsibility Model?",
    "1": "Physical security of DynamoDB",
    "2": "Patching of DynamoDB",
    "3": "Access to DynamoDB tables",
    "4": "Encryption of data at rest in DynamoDB",
    "5": "1",
    "answer": "C",
    "examName": "AWS Certified Cloud Practitioner CLF-C02"
  },
{
    "no": "6",
    "question": "Which option is a perspective that includes foundational capabilities of the AWS Cloud Adoption Framework (AWS CAF)?",
    "1": "Sustainability",
    "2": "Performance efficiency",
    "3": "Governance",
    "4": "Reliability",
    "5": "1",
    "answer": "C",
    "examName": "AWS Certified Cloud Practitioner CLF-C02"
  },
{
    "no": "7",
    "question": "A company is running and managing its own Docker environment on Amazon EC2 instances. The company wants an alternative to help manage cluster size, scheduling, and environment maintenance.\nWhich AWS service meets these requirements?",
    "1": "AWS Lambda",
    "2": "Amazon RDS",
    "3": "AWS Fargate",
    "4": "Amazon Athena",
    "5": "1",
    "answer": "C",
    "examName": "AWS Certified Cloud Practitioner CLF-C02"
  },
{
    "no": "8",
    "question": "A company wants to run a NoSQL database on Amazon EC2 instances.\nWhich task is the responsibility of AWS in this scenario?",
    "1": "Update the guest operating system of the EC2 instances.",
    "2": "Maintain high availability at the database layer.",
    "3": "Patch the physical infrastructure that hosts the EC2 instances.",
    "4": "Configure the security group firewall.",
    "5": "1",
    "answer": "C",
    "examName": "AWS Certified Cloud Practitioner CLF-C02"
  },
{
    "no": "9",
    "question": "Which AWS services or tools can identify rightsizing opportunities for Amazon EC2 instances? (Choose two.)",
    "1":"AWS Cost Explorer",
    "2": "AWS Billing Conductor",
    "3": "Amazon CodeGuru",
    "4": "Amazon SageMaker",
    "5": "AWS Compute Optimizer",
    "answer": "AE",
    "examName": "AWS Certified Cloud Practitioner CLF-C02"
  },
{
    "no": "10",
    "question": "Which of the following are benefits of using AWS Trusted Advisor? (Choose two.)",
    "1":"Providing high-performance container orchestration",
    "2": "Creating and rotating encryption keys",
    "3": "Detecting underutilized resources to save costs",
    "4": "Improving security by proactively monitoring the AWS environment",
    "5": "Implementing enforced tagging across AWS resources",
    "answer": "CD",
    "examName": "AWS Certified Cloud Practitioner CLF-C02"
  },
{
    "no": "11",
    "question": "Which of the following is an advantage that users experience when they move on-premises workloads to the AWS Cloud?",
    "1":"Elimination of expenses for running and maintaining data centers",
    "2": "Price discounts that are identical to discounts from hardware providers",
    "3": "Distribution of all operational controls to AWS",
    "4": "Elimination of operational expenses",
    "5": "1",
    "answer": "A",
    "examName": "AWS Certified Cloud Practitioner CLF-C02"
  },
{
    "no": "12",
    "question": "A company wants to manage deployed IT services and govern its infrastructure as code (IaC) templates.\nWhich AWS service will meet this requirement?",
    "1": "AWS Resource Explorer",
    "2": "AWS Service Catalog",
    "3": "AWS Organizations",
    "4": "AWS Systems Manager",
    "5": "1",
    "answer": "B",
    "examName": "AWS Certified Cloud Practitioner CLF-C02"
  },
{
    "no": "13",
    "question": "Which AWS service or tool helps users visualize, understand, and manage spending and usage over time?",
    "1": "AWS Organizations",
    "2": "AWS Pricing Calculator",
    "3": "AWS Cost Explorer",
    "4": "AWS Service Catalog",
    "5": "1",
    "answer": "C",
    "examName": "AWS Certified Cloud Practitioner CLF-C02"
  },
{
    "no": "14",
    "question": "A company is using a central data platform to manage multiple types of data for its customers. The company wants to use AWS services to discover, transform, and visualize the data.\nWhich combination of AWS services should the company use to meet these requirements? (Choose two.)",
    "1": "AWS Glue",
    "2": "Amazon Elastic File System (Amazon EFS)",
    "3": "Amazon Redshift",
    "4": "Amazon QuickSight",
    "5": "Amazon Quantum Ledger Database (Amazon QLDB)",
    "answer": "AD",
    "examName": "AWS Certified Cloud Practitioner CLF-C02"
  },
{
    "no": "15",
    "question": "A global company wants to migrate its third-party applications to the AWS Cloud. The company wants help from a global team of experts to complete the migration faster and more reliably in accordance with AWS internal best practices.\nWhich AWS service or resource will meet these requirements?",
    "1": "AWS Support",
    "2": "AWS Professional Services",
    "3": "AWS Launch Wizard",
    "4": "AWS Managed Services (AMS)",
    "5": "1",
    "answer": "B",
    "examName": "AWS Certified Cloud Practitioner CLF-C02"
  },
{
    "no": "16",
    "question": "An e-learning platform needs to run an application for 2 months each year. The application will be deployed on Amazon EC2 instances. Any application downtime during those 2 months must be avoided.\nWhich EC2 purchasing option will meet these requirements MOST cost-effectively?",
    "1": "Reserved Instances",
    "2": "Dedicated Hosts",
    "3": "Spot Instances",
    "4": "On-Demand Instances",
    "5": "1",
    "answer": "D",
    "examName": "AWS Certified Cloud Practitioner CLF-C02"
  },
{
    "no": "17",
    "question": "A developer wants to deploy an application quickly on AWS without manually creating the required resources.\nWhich AWS service will meet these requirements?",
    "1": "Amazon EC2",
    "2": "AWS Elastic Beanstalk",
    "3": "AWS CodeBuild",
    "4": "Amazon Personalize",
    "5": "1",
    "answer": "B",
    "examName": "AWS Certified Cloud Practitioner CLF-C02"
  },
{
    "no": "18",
    "question": "A company is storing sensitive customer data in an Amazon S3 bucket. The company wants to protect the data from accidental deletion or overwriting.\nWhich S3 feature should the company use to meet these requirements?",
    "1": "S3 Lifecycle rules",
    "2": "S3 Versioning",
    "3": "S3 bucket policies",
    "4": "S3 server-side encryption",
    "5": "1",
    "answer": "B",
    "examName": "AWS Certified Cloud Practitioner CLF-C02"
  },
{
    "no": "19",
    "question": "Which AWS service provides the ability to manage infrastructure as code?",
    "1": "AWS CodePipeline",
    "2": "AWS CodeDeploy",
    "3": "AWS Direct Connect",
    "4": "AWS CloudFormation",
    "5": "1",
    "answer": "D",
    "examName": "AWS Certified Cloud Practitioner CLF-C02"
  },
{
    "no": "20",
    "question": "An online gaming company needs to choose a purchasing option to run its Amazon EC2 instances for 1 year. The web traffic is consistent, and any increases in traffic are predictable. The EC2 instances must be online and available without any disruption.\nWhich EC2 instance purchasing option will meet these requirements MOST cost-effectively?",
    "1": "On-Demand Instances",
    "2": "Reserved Instances",
    "3": "Spot Instances",
    "4": "Spot Fleet",
    "5": "1",
    "answer": "B",
    "examName": "AWS Certified Cloud Practitioner CLF-C02"
  },
{
    "no": "21",
    "question": "Which AWS service or feature allows a user to establish a dedicated network connection between a company’s on-premises data center and the AWS Cloud?",
    "1": "AWS Direct Connect",
    "2": "VPC peering",
    "3": "AWS VPN",
    "4": "Amazon Route 53",
    "5": "1",
    "answer": "A",
    "examName": "AWS Certified Cloud Practitioner CLF-C02"
  },
{
    "no": "22",
    "question": "Which option is a physical location of the AWS global infrastructure?",
    "1": "AWS DataSync",
    "2": "AWS Region",
    "3": "Amazon Connect",
    "4": "AWS Organizations",
    "5": "1",
    "answer": "B",
    "examName": "AWS Certified Cloud Practitioner CLF-C02"
  },
{
    "no": "23",
    "question": "A company wants to protect its AWS Cloud information, systems, and assets while performing risk assessment and mitigation tasks.\nWhich pillar of the AWS Well-Architected Framework is supported by these goals?",
    "1": "Reliability",
    "2": "Security",
    "3": "Operational excellence",
    "4": "Performance efficiency",
    "5": "1",
    "answer": "B",
    "examName": "AWS Certified Cloud Practitioner CLF-C02"
  },
  {
      "no": "24",
      "question": "What is the purpose of having an internet gateway within a VPC?",
      "1": "To create a VPN connection to the VPC",
      "2": "To allow communication between the VPC and the internet",
      "3": "To impose bandwidth constraints on internet traffic",
      "4": "To load balance traffic from the internet across Amazon EC2 instances",
      "5": "1",
      "answer": "B",
      "examName": "AWS Certified Cloud Practitioner CLF-C02"
    },
  {
      "no": "25",
      "question": "A company is running a monolithic on-premises application that does not scale and is difficult to maintain. The company has a plan to migrate the application to AWS and divide the application into microservices.\nWhich best practice of the AWS Well-Architected Framework is the company following with this plan?",
      "1": "Integrate functional testing as part of AWS deployment.",
      "2": "Use automation to deploy changes.",
      "3": "Deploy the application to multiple locations.",
      "4": "Implement loosely coupled dependencies.",
      "5": "1",
      "answer": "D",
      "examName": "AWS Certified Cloud Practitioner CLF-C02"
    },
  {
      "no": "26",
      "question": "A company has an AWS account. The company wants to audit its password and access key rotation details for compliance purposes.\nWhich AWS service or tool will meet this requirement?",
      "1": "IAM Access Analyzer",
      "2": "AWS Artifact",
      "3": "IAM credential report",
      "4": "AWS Audit Manager",
      "5": "1",
      "answer": "C",
      "examName": "AWS Certified Cloud Practitioner CLF-C02"
    },
{
      "no": "27",
      "question": "A company wants to receive a notification when a specific AWS cost threshold is reached.\nWhich AWS services or tools can the company use to meet this requirement? (Choose two.)",
      "1": "Amazon Simple Queue Service (Amazon SQS)",
      "2": "AWS Budgets",
      "3": "Cost Explorer",
      "4": "Amazon CloudWatch",
      "5": "AWS Cost and Usage Report",
      "answer": "BD",
      "examName": "AWS Certified Cloud Practitioner CLF-C02"
    },
{
      "no": "28",
      "question": "Which AWS service or resource provides answers to the most frequently asked security-related questions that AWS receives from its users?",
      "1": "AWS Artifact",
      "2": "Amazon Connect",
      "3": "AWS Chatbot",
      "4": "AWS Knowledge Center",
      "5": "1",
      "answer": "D",
      "examName": "AWS Certified Cloud Practitioner CLF-C02"
    },
{
      "no": "29",
      "question": "Which tasks are customer responsibilities, according to the AWS shared responsibility model? (Choose two.)",
      "1": "Configure the AWS provided security group firewall.",
      "2": "Classify company assets in the AWS Cloud.",
      "3": "Determine which Availability Zones to use for Amazon S3 buckets.",
      "4": "Patch or upgrade Amazon DynamoDB.",
      "5": "Select Amazon EC2 instances to run AWS Lambda on.",
      "answer": "AB",
      "examName": "AWS Certified Cloud Practitioner CLF-C02"
    },
{
      "no": "30",
      "question": "Which of the following are pillars of the AWS Well-Architected Framework? (Choose two.)",
      "1": "Availability",
      "2": "Reliability",
      "3": "Scalability",
      "4": "Responsive design",
      "5": "Operational excellence",
      "answer": "BE",
      "examName": "AWS Certified Cloud Practitioner CLF-C02"
    },
{
      "no": "31",
      "question": "Which AWS service or feature is used to send both text and email messages from distributed applications?",
      "1": "Amazon Simple Notification Service (Amazon SNS)",
      "2": "Amazon Simple Email Service (Amazon SES)",
      "3": "Amazon CloudWatch alerts",
      "4": "Amazon Simple Queue Service (Amazon SQS)",
      "5": "1",
      "answer": "A",
      "examName": "AWS Certified Cloud Practitioner CLF-C02"
    },
{
      "no": "32",
      "question": "A user needs programmatic access to AWS resources through the AWS CLI or the AWS API.\nWhich option will provide the user with the appropriate access?",
      "1": "Amazon Inspector",
      "2": "Access keys",
      "3": "SSH public keys",
      "4": "AWS Key Management Service (AWS KMS) keys",
      "5": "1",
      "answer": "B",
      "examName": "AWS Certified Cloud Practitioner CLF-C02"
    },
{
      "no": "33",
      "question": "A company runs thousands of simultaneous simulations using AWS Batch. Each simulation is stateless, is fault tolerant, and runs for up to 3 hours.\nWhich pricing model enables the company to optimize costs and meet these requirements?",
      "1": "Reserved Instances",
      "2": "Spot Instances",
      "3": "On-Demand Instances",
      "4": "Dedicated Instances",
      "5": "1",
      "answer": "B",
      "examName": "AWS Certified Cloud Practitioner CLF-C02"
    },
{
      "no": "34",
      "question": "What does the concept of agility mean in AWS Cloud computing? (Choose two.)",
      "1": "The speed at which AWS resources are implemented",
      "2": "The speed at which AWS creates new AWS Regions",
      "3": "The ability to experiment quickly",
      "4": "The elimination of wasted capacity",
      "5": "The low cost of entry into cloud computing",
      "answer": "AC",
      "examName": "AWS Certified Cloud Practitioner CLF-C02"
    },
{
      "no": "35",
      "question": "A company needs to block SQL injection attacks.\nWhich AWS service or feature can meet this requirement?",
      "1": "AWS WAF",
      "2": "AWS Shield",
      "3": "Network ACLs",
      "4": "Security groups",
      "5": "1",
      "answer": "A",
      "examName": "AWS Certified Cloud Practitioner CLF-C02"
    },
{
      "no": "36",
      "question": "Which AWS service or feature identifies whether an Amazon S3 bucket or an IAM role has been shared with an external entity?",
      "1": "AWS Service Catalog",
      "2": "AWS Systems Manager",
      "3": "AWS IAM Access Analyzer",
      "4": "AWS Organizations",
      "5": "1",
      "answer": "C",
      "examName": "AWS Certified Cloud Practitioner CLF-C02"
    },
{
      "no": "37",
      "question": "A cloud practitioner needs to obtain AWS compliance reports before migrating an environment to the AWS Cloud.\nHow can these reports be generated?",
      "1": "Contact the AWS Compliance team.",
      "2": "Download the reports from AWS Artifact.",
      "3": "Open a case with AWS Support.",
      "4": "Generate the reports with Amazon Macie.",
      "5": "1",
      "answer": "B",
      "examName": "AWS Certified Cloud Practitioner CLF-C02"
    },
{
      "no": "38",
      "question": "An ecommerce company has migrated its IT infrastructure from an on-premises data center to the AWS Cloud.\nWhich cost is the company’s direct responsibility?",
      "1": "Cost of application software licenses",
      "2": "Cost of the hardware infrastructure on AWS",
      "3": "Cost of power for the AWS servers",
      "4": "Cost of physical security for the AWS data center",
      "5": "1",
      "answer": "A",
      "examName": "AWS Certified Cloud Practitioner CLF-C02"
    },
{
      "no": "39",
      "question": "A company is setting up AWS Identity and Access Management (IAM) on an AWS account.\nWhich recommendation complies with IAM security best practices?",
      "1": "Use the account root user access keys for administrative tasks.",
      "2": "Grant broad permissions so that all company employees can access the resources they need.",
      "3": "Turn on multi-factor authentication (MFA) for added security during the login process.",
      "4": "Avoid rotating credentials to prevent issues in production applications.",
      "5": "1",
      "answer": "C",
      "examName": "AWS Certified Cloud Practitioner CLF-C02"
    },
{
      "no": "40",
      "question": "Elasticity in the AWS Cloud refers to which of the following? (Choose two.)",
      "1": "How quickly an Amazon EC2 instance can be restarted",
      "2": "The ability to rightsize resources as demand shifts",
      "3": "The maximum amount of RAM an Amazon EC2 instance can use",
      "4": "The pay-as-you-go billing model",
      "5": "How easily resources can be procured when they are needed",
      "answer": "BE",
      "examName": "AWS Certified Cloud Practitioner CLF-C02"
    },
{
      "no": "41",
      "question": "Which service enables customers to audit API calls in their AWS accounts?",
      "1": "AWS CloudTrail",
      "2": "AWS Trusted Advisor",
      "3": "Amazon Inspector",
      "4": "AWS X-Ray",
      "5": "1",
      "answer": "A",
      "examName": "AWS Certified Cloud Practitioner CLF-C02"
    },
{
      "no": "42",
      "question": "What is a customer responsibility when using AWS Lambda according to the AWS shared responsibility model?",
      "1": "Managing the code within the Lambda function",
      "2": "Confirming that the hardware is working in the data center",
      "3": "Patching the operating system",
      "4": "Shutting down Lambda functions when they are no longer in use",
      "5": "1",
      "answer": "A",
      "examName": "AWS Certified Cloud Practitioner CLF-C02"
    },
{
      "no": "43",
      "question": "A company has 5 TB of data stored in Amazon S3. The company plans to occasionally run queries on the data for analysis.\nWhich AWS service should the company use to run these queries in the MOST cost-effective manner?",
      "1": "Amazon Redshift",
      "2": "Amazon Athena",
      "3": "Amazon Kinesis",
      "4": "Amazon RDS",
      "5": "1",
      "answer": "B",
      "examName": "AWS Certified Cloud Practitioner CLF-C02"
    },
{
      "no": "44",
      "question": "Which AWS service can be used at no additional cost?",
      "1": "Amazon SageMaker",
      "2": "AWS Config",
      "3": "AWS Organizations",
      "4": "Amazon CloudWatch",
      "5": "1",
      "answer": "C",
      "examName": "AWS Certified Cloud Practitioner CLF-C02"
    },
{
      "no": "45",
      "question": "Which AWS Cloud Adoption Framework (AWS CAF) capability belongs to the people perspective?",
      "1": "Data architecture",
      "2": "Event management",
      "3": "Cloud fluency",
      "4": "Strategic partnership",
      "5": "1",
      "answer": "C",
      "examName": "AWS Certified Cloud Practitioner CLF-C02"
    },
{
      "no": "46",
      "question": "A company wants to make an upfront commitment for continued use of its production Amazon EC2 instances in exchange for a reduced overall cost.\nWhich pricing options meet these requirements with the LOWEST cost? (Choose two.)",
      "1": "Spot Instances",
      "2": "On-Demand Instances",
      "3": "Reserved Instances",
      "4": "Savings Plans",
      "5": "Dedicated Hosts",
      "answer": "CD",
      "examName": "AWS Certified Cloud Practitioner CLF-C02"
    },
{
      "no": "47",
      "question": "A company wants to migrate its on-premises relational databases to the AWS Cloud. The company wants to use infrastructure as close to its current geographical location as possible.\nWhich AWS service or resource should the company use to select its Amazon RDS deployment area?",
      "1": "Amazon Connect",
      "2": "AWS Wavelength",
      "3": "AWS Regions",
      "4": "AWS Direct Connect",
      "5": "1",
      "answer": "C",
      "examName": "AWS Certified Cloud Practitioner CLF-C02"
    },
{
      "no": "48",
      "question": "A company is exploring the use of the AWS Cloud, and needs to create a cost estimate for a project before the infrastructure is provisioned.\nWhich AWS service or feature can be used to estimate costs before deployment?",
      "1": "AWS Free Tier",
      "2": "AWS Pricing Calculator",
      "3": "AWS Billing and Cost Management",
      "4": "AWS Cost and Usage Report",
      "5": "1",
      "answer": "B",
      "examName": "AWS Certified Cloud Practitioner CLF-C02"
    },
{
      "no": "49",
      "question": "A company is building an application that needs to deliver images and videos globally with minimal latency.\nWhich approach can the company use to accomplish this in a cost effective manner?",
      "1": "Deliver the content through Amazon CloudFront.",
      "2": "Store the content on Amazon S3 and enable S3 cross-region replication.",
      "3": "Implement a VPN across multiple AWS Regions.",
      "4": "Deliver the content through AWS PrivateLink.",
      "5": "1",
      "answer": "A",
      "examName": "AWS Certified Cloud Practitioner CLF-C02"
    },
{
      "no": "50",
      "question": "Which option is a benefit of the economies of scale based on the advantages of cloud computing?",
      "1": "The ability to trade variable expense for fixed expense",
      "2": "Increased speed and agility",
      "3": "Lower variable costs over fixed costs",
      "4": "Increased operational costs across data centers",
      "5": "1",
      "answer": "C",
      "examName": "AWS Certified Cloud Practitioner CLF-C02"
    },
{
      "no": "51",
      "question": "Which of the following is a software development framework that a company can use to define cloud resources as code and provision the resources through AWS CloudFormation?",
      "1": "AWS CLI",
      "2": "AWS Developer Center",
      "3": "AWS Cloud Development Kit (AWS CDK)",
      "4": "AWS CodeStar",
      "5": "1",
      "answer": "C",
      "examName": "AWS Certified Cloud Practitioner CLF-C02"
    },
{
      "no": "52",
      "question": "A company is developing an application that uses multiple AWS services. The application needs to use temporary, limited-privilege credentials for authentication with other AWS APIs.\nWhich AWS service or feature should the company use to meet these authentication requirements?",
      "1": "Amazon API Gateway",
      "2": "IAM users",
      "3": "AWS Security Token Service (AWS STS)",
      "4": "IAM instance profiles",
      "5": "1",
      "answer": "C",
      "examName": "AWS Certified Cloud Practitioner CLF-C02"
    },
{
      "no": "53",
      "question": "Which AWS service is a cloud security posture management (CSPM) service that aggregates alerts from various AWS services and partner products in a standardized format?",
      "1": "AWS Security Hub",
      "2": "AWS Trusted Advisor",
      "3": "Amazon EventBridge",
      "4": "Amazon GuardDuty",
      "5": "1",
      "answer": "A",
      "examName": "AWS Certified Cloud Practitioner CLF-C02"
    },
{
      "no": "54",
      "question": "Which AWS service is always provided at no charge?",
      "1": "Amazon S3",
      "2": "AWS Identity and Access Management (IAM)",
      "3": "Elastic Load Balancers",
      "4": "AWS WAF",
      "5": "1",
      "answer": "B",
      "examName": "AWS Certified Cloud Practitioner CLF-C02"
    },
{
      "no": "55",
      "question": "To reduce costs, a company is planning to migrate a NoSQL database to AWS.\nWhich AWS service is fully managed and can automatically scale throughput capacity to meet database workload demands?",
      "1": "Amazon Redshift",
      "2": "Amazon Aurora",
      "3": "Amazon DynamoDB",
      "4": "Amazon RDS",
      "5": "1",
      "answer": "C",
      "examName": "AWS Certified Cloud Practitioner CLF-C02"
    },
{
      "no": "56",
      "question": "A company is using Amazon DynamoDB.\nWhich task is the company’s responsibility, according to the AWS shared responsibility model?",
      "1": "Patch the operating system.",
      "2": "Provision hosts.",
      "3": "Manage database access permissions.",
      "4": "Secure the operating system.",
      "5": "1",
      "answer": "C",
      "examName": "AWS Certified Cloud Practitioner CLF-C02"
    },
{
      "no": "57",
      "question": "A company has a test AWS environment. A company is planning on testing an application within AWS. The application testing can be interrupted and does not need to run continuously.\nWhich Amazon EC2 purchasing option will meet these requirements MOST cost-effectively?",
      "1": "On-Demand Instances",
      "2": "Dedicated Instances",
      "3": "Spot Instances",
      "4": "Reserved Instances",
      "5": "1",
      "answer": "C",
      "examName": "AWS Certified Cloud Practitioner CLF-C02"
    },
{
      "no": "58",
      "question": "Which AWS service gives users the ability to discover and protect sensitive data that is stored in Amazon S3 buckets?",
      "1": "Amazon Macie",
      "2": "Amazon Detective",
      "3": "Amazon GuardDuty",
      "4": "AWS IAM Access Analyzer",
      "5": "1",
      "answer": "A",
      "examName": "AWS Certified Cloud Practitioner CLF-C02"
    },
{
      "no": "59",
      "question": "Which of the following services can be used to block network traffic to an instance? (Choose two.)",
      "1": "Security groups",
      "2": "Amazon Virtual Private Cloud (Amazon VPC) flow logs",
      "3": "Network ACLs",
      "4": "Amazon CloudWatch",
      "5": "AWS CloudTrail",
      "answer": "AC",
      "examName": "AWS Certified Cloud Practitioner CLF-C02"
    },
{
      "no": "60",
      "question": "Which AWS service can identify when an Amazon EC2 instance was terminated?",
      "1": "AWS Identity and Access Management (IAM)",
      "2": "AWS CloudTrail",
      "3": "AWS Compute Optimizer",
      "4": "Amazon EventBridge",
      "5": "1",
      "answer": "B",
      "examName": "AWS Certified Cloud Practitioner CLF-C02"
    },
    {
          "no": "61",
          "question": "Which of the following is a fully managed MySQL-compatible database?",
          "1": "Amazon S3",
          "2": "Amazon DynamoDB",
          "3": "Amazon Redshift",
          "4": "Amazon Aurora",
          "5": "1",
          "answer": "D",
          "examName": "AWS Certified Cloud Practitioner CLF-C02"
        },

    {
          "no": "62",
          "question": "Which AWS service supports a hybrid architecture that gives users the ability to extend AWS infrastructure, AWS services, APIs, and tools to data centers, co-location environments, or on-premises facilities?",
          "1": "AWS Snowmobile",
          "2": "AWS Local Zones",
          "3": "AWS Outposts",
          "4": "AWS Fargate",
          "5": "1",
          "answer": "C",
          "examName": "AWS Certified Cloud Practitioner CLF-C02"
        },
    {
          "no": "63",
          "question": "Which AWS service can run a managed PostgreSQL database that provides online transaction processing (OLTP)?",
          "1": "Amazon DynamoDB",
          "2": "Amazon Athena",
          "3": "Amazon RDS",
          "4": "Amazon EMR",
          "5": "1",
          "answer": "C",
          "examName": "AWS Certified Cloud Practitioner CLF-C02"
        },
    {
          "no": "64",
          "question": "A company wants to provide managed Windows virtual desktops and applications to its remote employees over secure network connections.\nWhich AWS services can the company use to meet these requirements? (Choose two.)",
          "1": "Amazon Connect",
          "2": "Amazon AppStream 2.0",
          "3": "Amazon WorkSpaces",
          "4": "AWS Site-to-Site VPN",
          "5": "Amazon Elastic Container Service (Amazon ECS)",
          "answer": "BC",
          "examName": "AWS Certified Cloud Practitioner CLF-C02"
        },
    {
          "no": "65",
          "question": "A company wants to monitor for misconfigured security groups that are allowing unrestricted access to specific ports.\nWhich AWS service will meet this requirement?",
          "1": "AWS Trusted Advisor",
          "2": "Amazon CloudWatch",
          "3": "Amazon GuardDuty",
          "4": "AWS Health Dashboard",
          "5": "1",
          "answer": "A",
          "examName": "AWS Certified Cloud Practitioner CLF-C02"
        },
    {
          "no": "66",
          "question": "Which AWS service is a key-value database that provides sub-millisecond latency on a large scale?",
          "1": "Amazon DynamoDB",
          "2": "Amazon Aurora",
          "3": "Amazon DocumentDB (with MongoDB compatibility)",
          "4": "Amazon Neptune",
          "5": "1",
          "answer": "A",
          "examName": "AWS Certified Cloud Practitioner CLF-C02"
        },
     {
           "no": "67",
           "question": "A company is deploying a machine learning (ML) research project that will require a lot of compute power over several months. The ML processing jobs do not need to run at specific times.\nWhich Amazon EC2 instance purchasing option will meet these requirements at the lowest cost?",
           "1": "On-Demand Instances",
           "2": "Spot Instances",
           "3": "Reserved Instances",
           "4": "Dedicated Instances",
           "5": "1",
           "answer": "B",
           "examName": "AWS Certified Cloud Practitioner CLF-C02"
         },
     {
           "no": "68",
           "question": "Which AWS services or features provide disaster recovery solutions for Amazon EC2 instances? (Choose two.)",
           "1": "EC2 Reserved Instances",
           "2": "EC2 Amazon Machine Images (AMIs)",
           "3": "Amazon Elastic Block Store (Amazon EBS) snapshots",
           "4": "AWS Shield",
           "5": "Amazon GuardDuty",
           "answer": "BC",
           "examName": "AWS Certified Cloud Practitioner CLF-C02"
         },
     {
           "no": "69",
           "question": "Which AWS service provides command line access to AWS tools and resources directly from a web browser?",
           "1": "AWS CloudHSM",
           "2": "AWS CloudShell",
           "3": "Amazon WorkSpaces",
           "4": "AWS Cloud Map",
           "5": "1",
           "answer": "B",
           "examName": "AWS Certified Cloud Practitioner CLF-C02"
         },
     {
           "no": "70",
           "question": "A network engineer needs to build a hybrid cloud architecture connecting on-premises networks to the AWS Cloud using AWS Direct Connect. The company has a few VPCs in a single AWS Region and expects to increase the number of VPCs to hundreds over time.\nWhich AWS service or feature should the engineer use to simplify and scale this connectivity as the VPCs increase in number?",
           "1": "VPC endpoints",
           "2": "AWS Transit Gateway",
           "3": "Amazon Route 53",
           "4": "AWS Secrets Manager",
           "5": "1",
           "answer": "B",
           "examName": "AWS Certified Cloud Practitioner CLF-C02"
         },
     {
           "no": "71",
           "question": "A company wants to assess its operational readiness. It also wants to identify and mitigate any operational risks ahead of a new product launch.\nWhich AWS Support plan offers guidance and support for this kind of event at no additional charge?",
           "1": "AWS Business Support",
           "2": "AWS Basic Support",
           "3": "AWS Developer Support",
           "4": "AWS Enterprise Support",
           "5": "1",
           "answer": "D",
           "examName": "AWS Certified Cloud Practitioner CLF-C02"
         },
     {
           "no": "72",
           "question": "A company wants to establish a schedule for rotating database user credentials.\nWhich AWS service will support this requirement with the LEAST amount of operational overhead?",
           "1": "AWS Systems Manager",
           "2": "AWS Secrets Manager",
           "3": "AWS License Manager",
           "4": "AWS Managed Services",
           "5": "1",
           "answer": "B",
           "examName": "AWS Certified Cloud Practitioner CLF-C02"
         },
     {
           "no": "73",
           "question": "Which AWS service or feature can be used to create a private connection between an on-premises workload and an AWS Cloud workload?",
           "1": "Amazon Route 53",
           "2": "Amazon Macie",
           "3": "AWS Direct Connect",
           "4": "AWS PrivateLink",
           "5": "1",
           "answer": "C",
           "examName": "AWS Certified Cloud Practitioner CLF-C02"
         },
     {
           "no": "74",
           "question": "Which AWS service is used to provide encryption for Amazon EBS?",
           "1": "AWS Certificate Manager",
           "2": "AWS Systems Manager",
           "3": "AWS KMS",
           "4": "AWS Config",
           "5": "1",
           "answer": "C",
           "examName": "AWS Certified Cloud Practitioner CLF-C02"
         },
     {
           "no": "75",
           "question": "A company wants to manage its AWS Cloud resources through a web interface.\nWhich AWS service will meet this requirement?",
           "1": "AWS Management Console",
           "2": "AWS CLI",
           "3": "AWS SDK",
           "4": "AWS Cloud9",
           "5": "1",
           "answer": "A",
           "examName": "AWS Certified Cloud Practitioner CLF-C02"
         },
     {
           "no": "76",
           "question": "Which of the following are advantages of the AWS Cloud? (Choose two.)",
           "1": "Trade variable expenses for capital expenses",
           "2": "High economies of scale",
           "3": "Launch globally in minutes",
           "4": "Focus on managing hardware infrastructure",
           "5": "Overprovision to ensure capacity",
           "answer": "BC",
           "examName": "AWS Certified Cloud Practitioner CLF-C02"
         },
     {
           "no": "77",
           "question": "Which AWS Cloud benefit is shown by an architecture’s ability to withstand failures with minimal downtime?",
           "1": "Agility",
           "2": "Elasticity",
           "3": "Scalability",
           "4": "High availability",
           "5": "1",
           "answer": "D",
           "examName": "AWS Certified Cloud Practitioner CLF-C02"
         },
     {
           "no": "78",
           "question": "A developer needs to maintain a development environment infrastructure and a production environment infrastructure in a repeatable fashion.\nWhich AWS service should the developer use to meet these requirements?",
           "1": "AWS Ground Station",
           "2": "AWS Shield",
           "3": "AWS IoT Device Defender",
           "4": "AWS CloudFormation",
           "5": "1",
           "answer": "D",
           "examName": "AWS Certified Cloud Practitioner CLF-C02"
         },
     {
           "no": "79",
           "question": "Which task is the customer’s responsibility, according to the AWS shared responsibility model?",
           "1": "Maintain the security of the AWS Cloud.",
           "2": "Configure firewalls and networks.",
           "3": "Patch the operating system of Amazon RDS instances.",
           "4": "Implement physical and environmental controls.",
           "5": "1",
           "answer": "B",
           "examName": "AWS Certified Cloud Practitioner CLF-C02"
         },
     {
           "no": "80",
           "question": "Which AWS service helps deliver highly available applications with fast failover for multi-Region and Multi-AZ architectures?",
           "1": "AWS WAF",
           "2": "AWS Global Accelerator",
           "3": "AWS Shield",
           "4": "AWS Direct Connect",
           "5": "1",
           "answer": "B",
           "examName": "AWS Certified Cloud Practitioner CLF-C02"
         },
     {
           "no": "81",
           "question": "A company has a set of ecommerce applications. The applications need to be able to send messages to each other.\nWhich AWS service meets this requirement?",
           "1": "AWS Auto Scaling",
           "2": "Elastic Load Balancing",
           "3": "Amazon Simple Queue Service (Amazon SQS)",
           "4": "Amazon Kinesis Data Streams",
           "5": "1",
           "answer": "C",
           "examName": "AWS Certified Cloud Practitioner CLF-C02"
         },
     {
           "no": "82",
           "question": "What are the benefits of consolidated billing for AWS Cloud services? (Choose two.)",
           "1": "Volume discounts",
           "2": "A minimal additional fee for use",
           "3": "One bill for multiple accounts",
           "4": "Installment payment options",
           "5": "Custom cost and usage budget creation",
           "answer": "AC",
           "examName": "AWS Certified Cloud Practitioner CLF-C02"
         },
     {
           "no": "83",
           "question": "A user wants to review all Amazon S3 buckets with ACLs and S3 bucket policies in the S3 console.\nWhich AWS service or resource will meet this requirement?",
           "1":"S3 Multi-Region Access Points",
           "2": "S3 Storage Lens",
           "3": "AWS IAM Identity Center (AWS Single Sign-On)",
           "4": "Access Analyzer for S3",
           "5": "1",
           "answer": "D",
           "examName": "AWS Certified Cloud Practitioner CLF-C02"
         },
     {
           "no": "84",
           "question": "What is the best resource for a user to find compliance-related information and reports about AWS?",
           "1":"AWS Artifact",
           "2": "AWS Marketplace",
           "3": "Amazon Inspector",
           "4": "AWS Support",
           "5": "1",
           "answer": "A",
           "examName": "AWS Certified Cloud Practitioner CLF-C02"
         },
     {
           "no": "85",
           "question": "Which AWS service enables companies to deploy an application close to end users?",
           "1":"AWS CloudFront",
           "2": "AWS Auto Scaling",
           "3": "AWS AppSync",
           "4": "Amazon Route 53",
           "5": "1",
           "answer": "A",
           "examName": "AWS Certified Cloud Practitioner CLF-C02"
         },
     {
           "no": "86",
           "question": "Which AWS service or feature improves network performance by sending traffic through the AWS worldwide network infrastructure?",
           "1":"Route table",
           "2": "AWS Transit Gateway",
           "3": "AWS Global Accelerator",
           "4": "Amazon VPC",
           "5": "1",
           "answer": "C",
           "examName": "AWS Certified Cloud Practitioner CLF-C02"
         },
     {
           "no": "87",
           "question": "Which AWS service provides highly durable object storage?",
           "1":"Amazon S3",
           "2": "Amazon Elastic File System (Amazon EFS)",
           "3": "Amazon Elastic Block Store (Amazon EBS)",
           "4": "Amazon FSx",
           "5": "1",
           "answer": "A",
           "examName": "AWS Certified Cloud Practitioner CLF-C02"
         },
     {
           "no": "88",
           "question": "Which responsibility belongs to AWS when a company hosts its databases on Amazon EC2 instances?",
           "1":"Database backups",
           "2": "Database software patches",
           "3": "Operating system patches",
           "4": "Operating system installations",
           "5": "1",
           "answer": "D",
           "examName": "AWS Certified Cloud Practitioner CLF-C02"
         },
     {
           "no": "89",
           "question": "Which of the following are advantages of moving to the AWS Cloud? (Choose two.)",
           "1":"The ability to turn over the responsibility for all security to AWS.",
           "2": "The ability to use the pay-as-you-go model.",
           "3": "The ability to have full control over the physical infrastructure.",
           "4": "No longer having to guess what capacity will be required.",
           "5": "No longer worrying about users access controls.",
           "answer": "BD",
           "examName": "AWS Certified Cloud Practitioner CLF-C02"
         },
     {
           "no": "90",
           "question": "Which AWS service is a hybrid cloud storage service that provides on-premises users access to virtually unlimited cloud storage?",
           "1":"AWS DataSync",
           "2": "Amazon S3 Glacier",
           "3": "AWS Storage Gateway",
           "4": "Amazon Elastic Block Store (Amazon EBS)",
           "5": "1",
           "answer": "C",
           "examName": "AWS Certified Cloud Practitioner CLF-C02"
         },
     {
           "no": "91",
           "question": "A company plans to migrate to AWS and wants to create cost estimates for its AWS use cases.\nWhich AWS service or tool can the company use to meet these requirements?",
           "1":"AWS Pricing Calculator",
           "2": "Amazon CloudWatch",
           "3": "AWS Cost Explorer",
           "4": "AWS Budgets",
           "5": "1",
           "answer": "A",
           "examName": "AWS Certified Cloud Practitioner CLF-C02"
         },
     {
           "no": "92",
           "question": "Which tool should a developer use to integrate AWS service features directly into an application?",
           "1": "AWS Software Development Kit",
           "2": "AWS CodeDeploy",
           "3": "AWS Lambda",
           "4": "AWS Batch",
           "5": "1",
           "answer": "A",
           "examName": "AWS Certified Cloud Practitioner CLF-C02"
         },
     {
           "no": "93",
           "question": "Which of the following is a recommended design principle of the AWS Well-Architected Framework?",
           "1": "Reduce downtime by making infrastructure changes infrequently and in large increments.",
           "2": "Invest the time to configure infrastructure manually.",
           "3": "Learn to improve from operational failures.",
           "4": "Use monolithic application design for centralization.",
           "5": "1",
           "answer": "C",
           "examName": "AWS Certified Cloud Practitioner CLF-C02"
         },
     {
           "no": "94",
           "question": "Using AWS Identity and Access Management (IAM) to grant access only to the resources needed to perform a task is a concept known as:",
           "1": "restricted access.",
           "2": "as-needed access.",
           "3": "least privilege access.",
           "4": "token access.",
           "5": "1",
           "answer": "C",
           "examName": "AWS Certified Cloud Practitioner CLF-C02"
         },
     {
           "no": "95",
           "question": "Which AWS service or tool can be used to set up a firewall to control traffic going into and coming out of an Amazon VPC subnet?",
           "1": "Security group",
           "2": "AWS WAF",
           "3": "AWS Firewall Manager",
           "4": "Network ACL",
           "5": "1",
           "answer": "D",
           "examName": "AWS Certified Cloud Practitioner CLF-C02"
         },
     {
           "no": "96",
           "question": "A company wants to operate a data warehouse to analyze data without managing the data warehouse infrastructure.\nWhich AWS service will meet this requirement?",
           "1": "Amazon Aurora",
           "2": "Amazon Redshift Serverless",
           "3": "AWS Lambda",
           "4": "Amazon RDS",
           "5": "1",
           "answer": "B",
           "examName": "AWS Certified Cloud Practitioner CLF-C02"
         },
     {
           "no": "97",
           "question": "How does AWS Cloud computing help businesses reduce costs? (Choose two.)",
           "1": "AWS charges the same prices for services in every AWS Region.",
           "2": "AWS enables capacity to be adjusted on demand.",
           "3": "AWS offers discounts for Amazon EC2 instances that remain idle for more than 1 week.",
           "4": "AWS does not charge for data sent from the AWS Cloud to the internet.",
           "5": "AWS eliminates many of the costs of building and maintaining on-premises data centers.",
           "answer": "BE",
           "examName": "AWS Certified Cloud Practitioner CLF-C02"
         },
     {
           "no": "98",
           "question": "A company wants to grant users in one AWS account access to resources in another AWS account. The users do not currently have permission to access the resources.\nWhich AWS service will meet this requirement?",
           "1": "IAM group",
           "2": "IAM role",
           "3": "IAM tag",
           "4": "IAM Access Analyzer",
           "5": "1",
           "answer": "B",
           "examName": "AWS Certified Cloud Practitioner CLF-C02"
         },
     {
           "no": "99",
           "question": "Which task is the responsibility of AWS when using AWS services?",
           "1": "Management of IAM user permissions",
           "2": "Creation of security group rules for outbound access",
           "3": "Maintenance of physical and environmental controls",
           "4": "Application of Amazon EC2 operating system patches",
           "5": "1",
           "answer": "C",
           "examName": "AWS Certified Cloud Practitioner CLF-C02"
         },
     {
           "no": "100",
           "question": "A company wants to automate infrastructure deployment by using infrastructure as code (IaC). The company wants to scale production stacks so the stacks can be deployed in multiple AWS Regions.\nWhich AWS service will meet these requirements?",
           "1": "Amazon CloudWatch",
           "2": "AWS Config",
           "3": "AWS Trusted Advisor",
           "4": "AWS CloudFormation",
           "5": "1",
           "answer": "D",
           "examName": "AWS Certified Cloud Practitioner CLF-C02"
         },
     {
           "no": "101",
           "question": "Which option is an AWS Cloud Adoption Framework (AWS CAF) platform perspective capability?",
           "1": "Data architecture",
           "2": "Data protection",
           "3": "Data governance",
           "4": "Data science",
           "5": "1",
           "answer": "A",
           "examName": "AWS Certified Cloud Practitioner CLF-C02"
         },
     {
           "no": "102",
           "question": "A company is running a workload in the AWS Cloud.\nWhich AWS best practice ensures the MOST cost-effective architecture for the workload?",
           "1": "Loose coupling",
           "2": "Rightsizing",
           "3": "Caching",
           "4": "Redundancy",
           "5": "1",
           "answer": "B",
           "examName": "AWS Certified Cloud Practitioner CLF-C02"
         },
     {
           "no": "103",
           "question": "A company is using a third-party service to back up 10 TB of data to a tape library. The on-premises backup server is running out of space. The company wants to use AWS services for the backups without changing its existing backup workflows.\nWhich AWS service should the company use to meet these requirements?",
           "1": "Amazon Elastic Block Store (Amazon EBS)",
           "2": "AWS Storage Gateway",
           "3": "Amazon Elastic Container Service (Amazon ECS)",
           "4": "AWS Lambda",
           "5": "1",
           "answer": "B",
           "examName": "AWS Certified Cloud Practitioner CLF-C02"
         },
     {
           "no": "104",
           "question": "Which AWS tool gives users the ability to plan their service usage, service costs, and instance reservations, and also allows them to set custom alerts when their costs or usage exceed established thresholds?",
           "1": "Cost Explorer",
           "2": "AWS Budgets",
           "3": "AWS Cost and Usage Report",
           "4": "Reserved Instance reporting",
           "5": "1",
           "answer": "B",
           "examName": "AWS Certified Cloud Practitioner CLF-C02"
         },
     {
           "no": "105",
           "question": "Which tasks are the customer’s responsibility, according to the AWS shared responsibility model? (Choose two.)",
           "1": "Establish the global infrastructure.",
           "2": "Perform client-side data encryption.",
           "3": "Configure IAM credentials.",
           "4": "Secure edge locations.",
           "5": "Patch Amazon RDS DB instances.",
           "answer": "BC",
           "examName": "AWS Certified Cloud Practitioner CLF-C02"
         },
     {
           "no": "106",
           "question": "A developer has been hired by a large company and needs AWS credentials.\nWhich are security best practices that should be followed? (Choose two.)",
           "1": "Grant the developer access to only the AWS resources needed to perform the job.",
           "2": "Share the AWS account root user credentials with the developer.",
           "3": "Add the developer to the administrator’s group in AWS IAM.",
           "4": "Configure a password policy that ensures the developer’s password cannot be changed.",
           "5": " Ensure the account password policy requires a minimum length.",
           "answer": "AE",
           "examName": "AWS Certified Cloud Practitioner CLF-C02"
         },
     {
           "no": "107",
           "question": "A company has multiple AWS accounts that include compute workloads that cannot be interrupted. The company wants to obtain billing discounts that are based on the company’s use of AWS services.\nWhich AWS feature or purchasing option will meet these requirements?",
           "1": "Resource tagging",
           "2": "Consolidated billing",
           "3": "Pay-as-you-go pricing",
           "4": "Spot Instances",
           "5": "1",
           "answer": "B",
           "examName": "AWS Certified Cloud Practitioner CLF-C02"
         },
     {
           "no": "108",
           "question": "A user wants to allow applications running on an Amazon EC2 instance to make calls to other AWS services. The access granted must be secure.\nWhich AWS service or feature should be used?",
           "1": "Security groups",
           "2": "AWS Firewall Manager",
           "3": "IAM roles",
           "4": "IAM user SSH keys",
           "5": "1",
           "answer": "C",
           "examName": "AWS Certified Cloud Practitioner CLF-C02"
         },
     {
           "no": "109",
           "question": "A company wants a fully managed Windows file server for its Windows-based applications.\nWhich AWS service will meet this requirement?",
           "1": "Amazon FSx",
           "2": "Amazon Elastic Kubernetes Service (Amazon EKS)",
           "3": "Amazon Elastic Container Service (Amazon ECS)",
           "4": "Amazon EMR",
           "5": "1",
           "answer": "A",
           "examName": "AWS Certified Cloud Practitioner CLF-C02"
         },
     {
           "no": "110",
           "question": "A company wants to migrate its NFS on-premises workload to AWS.\nWhich AWS Storage Gateway type should the company use to meet this requirement?",
           "1": "Tape Gateway",
           "2": "Volume Gateway",
           "3": "Amazon FSx File Gateway",
           "4": "Amazon S3 File Gateway",
           "5": "1",
           "answer": "D",
           "examName": "AWS Certified Cloud Practitioner CLF-C02"
         },
     {
           "no": "111",
           "question": "A company needs to track the activity in its AWS accounts, and needs to know when an API call is made against its AWS resources.\nWhich AWS tool or service can be used to meet these requirements?",
           "1": "Amazon CloudWatch",
           "2": "Amazon Inspector",
           "3": "AWS CloudTrail",
           "4": "AWS IAM",
           "5": "1",
           "answer": "C",
           "examName": "AWS Certified Cloud Practitioner CLF-C02"
         },
     {
           "no": "112",
           "question": "A company has an uninterruptible application that runs on Amazon EC2 instances. The application constantly processes a backlog of files in an Amazon Simple Queue Service (Amazon SQS) queue. This usage is expected to continue to grow for years.\nWhat is the MOST cost-effective EC2 instance purchasing model to meet these requirements?",
           "1": "Spot Instances",
           "2": "On-Demand Instances",
           "3": "Savings Plans",
           "4": "Dedicated Hosts",
           "5": "1",
           "answer": "C",
           "examName": "AWS Certified Cloud Practitioner CLF-C02"
         },
     {
           "no": "113",
           "question": "A company wants an AWS service to provide product recommendations based on its customer data.\nWhich AWS service will meet this requirement?",
           "1": "Amazon Polly",
           "2": "Amazon Personalize",
           "3": "Amazon Comprehend",
           "4": "Amazon Rekognition",
           "5": "1",
           "answer": "B",
           "examName": "AWS Certified Cloud Practitioner CLF-C02"
         },
     {
           "no": "114",
           "question": "A company is planning its migration to the AWS Cloud. The company is identifying its capability gaps by using the AWS Cloud Adoption Framework (AWS CAF) perspectives.\nWhich phase of the cloud transformation journey includes these identification activities?",
           "1": "Envision",
           "2": "Align",
           "3": "Scale",
           "4": "Launch",
           "5": "1",
           "answer": "B",
           "examName": "AWS Certified Cloud Practitioner CLF-C02"
         },
     {
           "no": "115",
           "question": "A social media company wants to protect its web application from common web exploits such as SQL injections and cross-site scripting.\nWhich AWS service will meet these requirements?",
           "1": "Amazon Inspector",
           "2": "AWS WAF",
           "3": "Amazon GuardDuty",
           "4": "Amazon CloudWatch",
           "5": "1",
           "answer": "B",
           "examName": "AWS Certified Cloud Practitioner CLF-C02"
         },
     {
           "no": "116",
           "question": "Which fully managed AWS service assists with the creation, testing, and management of custom Amazon EC2 images?",
           "1": "EC2 Image Builder",
           "2": "Amazon Machine Image (AMI)",
           "3": "AWS Launch Wizard",
           "4": "AWS Elastic Beanstalk",
           "5": "1",
           "answer": "A",
           "examName": "AWS Certified Cloud Practitioner CLF-C02"
         },
     {
           "no": "117",
           "question": "A company wants an automated process to continuously scan its Amazon EC2 instances for software vulnerabilities.\nWhich AWS service will meet these requirements?",
           "1": "Amazon GuardDuty",
           "2": "Amazon Inspector",
           "3": "Amazon Detective",
           "4": "Amazon Cognito",
           "5": "1",
           "answer": "B",
           "examName": "AWS Certified Cloud Practitioner CLF-C02"
         },
     {
           "no": "118",
           "question": "A company needs to perform data processing once a week that typically takes about 5 hours to complete.\nWhich AWS service should the company use for this workload?",
           "1": "AWS Lambda",
           "2": "Amazon EC2",
           "3": "AWS CodeDeploy",
           "4": "AWS Wavelength",
           "5": "1",
           "answer": "B",
           "examName": "AWS Certified Cloud Practitioner CLF-C02"
         },
     {
           "no": "119",
           "question": "Which AWS service or feature provides log information of the inbound and outbound traffic on network interfaces in a VPC?",
           "1": "Amazon CloudWatch Logs",
           "2": "AWS CloudTrail",
           "3": "VPC Flow Logs",
           "4": "AWS Identity and Access Management (IAM)",
           "5": "1",
           "answer": "C",
           "examName": "AWS Certified Cloud Practitioner CLF-C02"
         },
     {
           "no": "120",
           "question": "A company wants to design a centralized storage system to manage the configuration data and passwords for its critical business applications.\nWhich AWS service or capability will meet these requirements MOST cost-effectively?",
           "1": "AWS Systems Manager Parameter Store",
           "2": "AWS Secrets Manager",
           "3": "AWS Config",
           "4": "Amazon S3",
           "5": "1",
           "answer": "A",
           "examName": "AWS Certified Cloud Practitioner CLF-C02"
         },
          {
                "no": "121",
                "question": "A company plans to deploy containers on AWS. The company wants full control of the compute resources that host the containers. Which AWS service will meet these requirements?",
                "1": "Amazon Elastic Kubernetes Service (Amazon EKS)",
                "2": "AWS Fargate",
                "3": "Amazon EC2",
                "4": "Amazon Elastic Container Service (Amazon ECS)",
                "5": "1",
                "answer": "C",
                "examName": "AWS Certified Cloud Practitioner CLF-C02"
              },
          {
                "no": "122",
                "question": "Which AWS service or feature allows users to create new AWS accounts, group multiple accounts to organize workflows, and apply policies to groups of accounts?",
                "1": "AWS Identity and Access Management (IAM)",
                "2": "AWS Trusted Advisor",
                "3": "AWS CloudFormation",
                "4": "AWS Organizations",
                "5": "1",
                "answer": "D",
                "examName": "AWS Certified Cloud Practitioner CLF-C02"
              },
          {
                "no": "123",
                "question": "A company wants to store and retrieve files in Amazon S3 for its existing on-premises applications by using industry-standard file system protocols.\nWhich AWS service will meet these requirements?",
                "1": "AWS DataSync",
                "2": "AWS Snowball Edge",
                "3": "Amazon S3 File Gateway",
                "4": "AWS Transfer Family",
                "5": "1",
                "answer": "C",
                "examName": "AWS Certified Cloud Practitioner CLF-C02"
              },
          {
                "no": "124",
                "question": "A company wants to block SQL injection attacks.\nWhich AWS service or feature should the company use to meet this requirement?",
                "1": "AWS WAF",
                "2": "Network ACLs",
                "3": "Security groups",
                "4": "AWS Certificate Manager (ACM)",
                "5": "1",
                "answer": "A",
                "examName": "AWS Certified Cloud Practitioner CLF-C02"
              },
          {
                "no": "125",
                "question": "A company wants a unified tool to provide a consistent method to interact with AWS services.\nWhich AWS service or tool will meet this requirement?",
                "1": "AWS CLI",
                "2": "Amazon Elastic Container Service (Amazon ECS)",
                "3": "AWS Cloud9",
                "4": "AWS Virtual Private Network (AWS VPN)",
                "5": "1",
                "answer": "A",
                "examName": "AWS Certified Cloud Practitioner CLF-C02"
              },
          {
                "no": "126",
                "question": "A company needs to evaluate its AWS environment and provide best practice recommendations in five categories: cost, performance, service limits, fault tolerance and security.\nWhich AWS service can the company use to meet these requirements?",
                "1": "AWS Shield",
                "2": "AWS WAF",
                "3": "AWS Trusted Advisor",
                "4": "AWS Service Catalog",
                "5": "1",
                "answer": "C",
                "examName": "AWS Certified Cloud Practitioner CLF-C02"
              },
          {
                "no": "127",
                "question": "Which perspective in the AWS Cloud Adoption Framework (AWS CAF) includes capabilities for configuration management and patch management?",
                "1": "Platform",
                "2": "Operations",
                "3": "Security",
                "4": "Governance",
                "5": "1",
                "answer": "B",
                "examName": "AWS Certified Cloud Practitioner CLF-C02"
              },
          {
                "no": "128",
                "question": "A company has a compute workload that is steady, predictable, and uninterruptible.\nWhich Amazon EC2 instance purchasing options meet these requirements MOST cost-effectively? (Choose two.)",
                "1": "On-Demand Instances",
                "2": "Reserved Instances",
                "3": "Spot Instances",
                "4": "Saving Plans",
                "5": "Dedicated Hosts",
                "answer": "BD",
                "examName": "AWS Certified Cloud Practitioner CLF-C02"
              },
          {
                "no": "129",
                "question": "Which Amazon EC2 pricing model is the MOST cost efficient for an uninterruptible workload that runs once a year for 24 hours?",
                "1": "On-Demand Instances",
                "2": "Reserved Instances",
                "3": "Spot Instances",
                "4": "Dedicated Instances",
                "5": "1",
                "answer": "A",
                "examName": "AWS Certified Cloud Practitioner CLF-C02"
              },
          {
                "no": "130",
                "question": "Which option is a shared responsibility between AWS and its customers under the AWS shared responsibility model?",
                "1": "Configuration of Amazon EC2 instance operating systems",
                "2": "Application file system server-side encryption",
                "3": "Patch management",
                "4": "Security of the physical infrastructure",
                "5": "1",
                "answer": "C",
                "examName": "AWS Certified Cloud Practitioner CLF-C02"
              },
          {
                "no": "131",
                "question": "A company wants to migrate its on-premises workloads to the AWS Cloud. The company wants to separate workloads for chargeback to different departments.\nWhich AWS services or features will meet these requirements? (Choose two.)",
                "1": "Placement groups",
                "2": "Consolidated billing",
                "3": "Edge locations",
                "4": "AWS Config",
                "5": "Multiple AWS accounts",
                "answer": "BE",
                "examName": "AWS Certified Cloud Practitioner CLF-C02"
              },
          {
                "no": "132",
                "question": "Which task is a responsibility of AWS, according to the AWS shared responsibility model?",
                "1": "Enable client-side encryption for objects that are stored in Amazon S3.",
                "2": "Configure IAM security policies to comply with the principle of least privilege.",
                "3": "Patch the guest operating system on an Amazon EC2 instance.",
                "4": "Apply updates to the Nitro Hypervisor.",
                "5": "1",
                "answer": "D",
                "examName": "AWS Certified Cloud Practitioner CLF-C02"
              },
          {
                "no": "133",
                "question": "Which option is a benefit of using AWS for cloud computing?",
                "1": "Trade variable expense for fixed expense",
                "2": "Pay-as-you-go pricing",
                "3": "Decreased speed and agility",
                "4": "Spending money running and maintaining data centers",
                "5": "1",
                "answer": "B",
                "examName": "AWS Certified Cloud Practitioner CLF-C02"
              },
          {
                "no": "134",
                "question": "Which option is an AWS Cloud Adoption Framework (AWS CAF) business perspective capability?",
                "1": "Culture evolution",
                "2": "Event management",
                "3": "Data monetization",
                "4": "Platform architecture",
                "5": "1",
                "answer": "C",
                "examName": "AWS Certified Cloud Practitioner CLF-C02"
              },
          {
                "no": "135",
                "question": "A company is assessing its AWS Business Support plan to determine if the plan still meets the company’s needs. The company is considering switching to AWS Enterprise Support.\nWhich additional benefit will the company receive with AWS Enterprise Support?",
                "1": "A full set of AWS Trusted Advisor checks",
                "2": "Phone, email, and chat access to cloud support engineers 24 hours a day, 7 days a week",
                "3": "A designated technical account manager (TAM) to assist in monitoring and optimization",
                "4": "A consultative review and architecture guidance for the company’s applications",
                "5": "1",
                "answer": "C",
                "examName": "AWS Certified Cloud Practitioner CLF-C02"
              },
          {
                "no": "136",
                "question": "Which pricing model will interrupt a running Amazon EC2 instance if capacity becomes temporarily unavailable?",
                "1": "On-Demand Instances",
                "2": "Standard Reserved Instances",
                "3": "Spot Instances",
                "4": "Convertible Reserved Instances",
                "5": "1",
                "answer": "C",
                "examName": "AWS Certified Cloud Practitioner CLF-C02"
              },
          {
                "no": "137",
                "question": "Which options are AWS Cloud Adoption Framework (AWS CAF) security perspective capabilities? (Choose two.)",
                "1": "Observability",
                "2": "Incident and problem management",
                "3": "Incident response",
                "4": "Infrastructure protection",
                "5": "Availability and continuity",
                "answer": "CD",
                "examName": "AWS Certified Cloud Practitioner CLF-C02"
              },
          {
                "no": "138",
                "question": "A company wants to run its workload on Amazon EC2 instances for more than 1 year. This workload will run continuously.\nWhich option offers a discounted hourly rate compared to the hourly rate of On-Demand Instances?",
                "1": "AWS Graviton processor",
                "2": "Dedicated Hosts",
                "3": "EC2 Instance Savings Plans",
                "4": "Amazon EC2 Auto Scaling instances",
                "5": "1",
                "answer": "C",
                "examName": "AWS Certified Cloud Practitioner CLF-C02"
              },
          {
                "no": "139",
                "question": "Which characteristic of the AWS Cloud helps users eliminate underutilized CPU capacity?",
                "1":"Agility",
                "2": "Elasticity",
                "3": "Reliability",
                "4": "Durability",
                "5": "1",
                "answer": "B",
                "examName": "AWS Certified Cloud Practitioner CLF-C02"
              },
          {
                "no": "140",
                "question": "Which AWS services can a company use to achieve a loosely coupled architecture? (Choose two.)",
                "1":"Amazon WorkSpaces",
                "2": "Amazon Simple Queue Service (Amazon SQS)",
                "3": "Amazon Connect",
                "4": "AWS Trusted Advisor",
                "5": "AWS Step Functions",
                "answer": "BE",
                "examName": "AWS Certified Cloud Practitioner CLF-C02"
              },
          {
                "no": "141",
                "question": "Which AWS Cloud service can send alerts to customers if custom spending thresholds are exceeded?",
                "1":"AWS Budgets",
                "2": "AWS Cost Explorer",
                "3": "AWS Cost Allocation Tags",
                "4": "AWS Organizations",
                "5": "1",
                "answer": "A",
                "examName": "AWS Certified Cloud Practitioner CLF-C02"
              },
          {
                "no": "142",
                "question": "A company plans to migrate to the AWS Cloud. The company wants to use the AWS Cloud Adoption Framework (AWS CAF) to define and track business outcomes as part of its cloud transformation journey.\nWhich AWS CAF governance perspective capability will meet these requirements?",
                "1": "Benefits management",
                "2": "Risk management",
                "3": "Application portfolio management",
                "4": "Cloud financial management",
                "5": "1",
                "answer": "A",
                "examName": "AWS Certified Cloud Practitioner CLF-C02"
              },
          {
                "no": "143",
                "question": "A company needs to quickly and securely move files over long distances between its client and an Amazon S3 bucket.\nWhich S3 feature will meet this requirement?",
                "1": "S3 Versioning",
                "2": "S3 Transfer Acceleration",
                "3": "S3ACLs",
                "4": "S3 Intelligent-Tiering",
                "5": "1",
                "answer": "B",
                "examName": "AWS Certified Cloud Practitioner CLF-C02"
              },
          {
                "no": "144",
                "question": "A company needs to continuously run an experimental workload on an Amazon EC2 instance and stop the instance after 12 hours.\nWhich instance purchasing option will meet this requirement MOST cost-effectively?",
                "1": "On-Demand Instances",
                "2": "Reserved Instances",
                "3": "Spot Instances",
                "4": "Dedicated Instances",
                "5": "1",
                "answer": "A",
                "examName": "AWS Certified Cloud Practitioner CLF-C02"
              },
            {
                  "no": "145",
                  "question": "Which cloud transformation journey phase of the AWS Cloud Adoption Framework (AWS CAF) focuses on demonstrating how the cloud helps accelerate business outcomes?",
                  "1": "Scale",
                  "2": "Envision",
                  "3": "Align",
                  "4": "Launch",
                  "5": "1",
                  "answer": "B",
                  "examName": "AWS Certified Cloud Practitioner CLF-C02"
                },
            {
                  "no": "146",
                  "question": "Which option is a customer responsibility under the AWS shared responsibility model?",
                  "1": "Maintenance of underlying hardware of Amazon EC2 instances",
                  "2": "Application data security",
                  "3": "Physical security of data centers",
                  "4": "Maintenance of VPC components",
                  "5": "1",
                  "answer": "B",
                  "examName": "AWS Certified Cloud Practitioner CLF-C02"
                },
            {
                  "no": "147",
                  "question": "A company wants its Amazon EC2 instances to operate in a highly available environment, even if there is a natural disaster in a particular geographic area.\nWhich approach will achieve this goal?",
                  "1": "Use EC2 instances in multiple AWS Regions.",
                  "2": "Use EC2 instances in multiple Amazon CloudFront locations.",
                  "3": "Use EC2 instances in multiple edge locations.",
                  "4": "Use EC2 instances in AWS Local Zones.",
                  "5": "1",
                  "answer": "A",
                  "examName": "AWS Certified Cloud Practitioner CLF-C02"
                },
            {
                  "no": "148",
                  "question": "A company wants to modernize and convert a monolithic application into microservices. The company wants to move the application to AWS.\nWhich migration strategy should the company use?",
                  "1": "Rehost",
                  "2": "Replatform",
                  "3": "Repurchase",
                  "4": "Refactor",
                  "5": "1",
                  "answer": "D",
                  "examName": "AWS Certified Cloud Practitioner CLF-C02"
                },
            {
                  "no": "149",
                  "question": "A systems administrator created a new IAM user for a developer and assigned the user an access key instead of a user name and password. What is the access key used for?",
                  "1": "To access the AWS account as the AWS account root user",
                  "2": "To access the AWS account through the AWS Management Console",
                  "3": "To access the AWS account through a CLI",
                  "4": "To access all of a company’s AWS accounts",
                  "5": "1",
                  "answer": "C",
                  "examName": "AWS Certified Cloud Practitioner CLF-C02"
                },
            {
                  "no": "150",
                  "question": "Which option is an environment that consists of one or more data centers?",
                  "1": "Amazon CloudFront",
                  "2": "Availability Zone",
                  "3": "VPC",
                  "4": "AWS Outposts",
                  "5": "1",
                  "answer": "B",
                  "examName": "AWS Certified Cloud Practitioner CLF-C02"
                },
                {
                      "no": "151",
                      "question": "A company is moving an on-premises data center to the AWS Cloud. The company must migrate 50 petabytes of file storage data to AWS with the least possible operational overhead.\nWhich AWS service or resource should the company use to meet these requirements?",
                      "1": "AWS Snowmobile",
                      "2": "AWS Snowball Edge",
                      "3": "AWS Snowball Edge",
                      "4": "AWS Database Migration Service (AWS DMS)",
                      "5": "1",
                      "answer": "A",
                      "examName": "AWS Certified Cloud Practitioner CLF-C02"
                    },
                {
                      "no": "152",
                      "question": "A company has an application with robust hardware requirements. The application must be accessed by students who are using lightweight, low-cost laptops.\nWhich AWS service will help the company deploy the application without investing in backend infrastructure or high-end client hardware?",
                      "1": "Amazon AppStream 2.0",
                      "2": "AWS AppSync",
                      "3": "Amazon WorkLink",
                      "4": "AWS Elastic Beanstalk",
                      "5": "1",
                      "answer": "A",
                      "examName": "AWS Certified Cloud Practitioner CLF-C02"
                    },
  {
    "1": "Amazon Aurora",
    "2": "Amazon Elastic File System (Amazon EFS)",
    "3": "Amazon Elastic Block Store (Amazon EBS)",
    "4": "Amazon S3",
    "5": "1",
    "no": "153",
    "question": "A company wants to query its server logs to gain insights about its customers’ experiences.Which AWS service will store this data MOST cost-effectively?",
    "answer": "D",
    "examName": "AWS Certified Cloud Practitioner CLF-C02"
  },
    {
        "1": "Design tightly coupled components.",
        "2": "Build a single application component that can handle all the application functionality.",
        "3": "Make large changes on fewer iterations to reduce chances of failure.",
        "4": "Avoid monolithic architecture by segmenting workloads.",
        "5": "1",
        "no": "154",
        "question": "Which of the following is a recommended design principle for AWS Cloud architecture?",
        "answer": "D",
        "examName": "AWS Certified Cloud Practitioner CLF-C02"
      },
        {
          "1": "AWS CloudTrail",
          "2": "Amazon Inspector",
          "3": "AWS WAF",
          "4": "AWS Config",
          "5": "1",
          "no": "155",
          "question": "Which AWS service helps users audit API activity across their AWS account?",
          "answer": "A",
          "examName": "AWS Certified Cloud Practitioner CLF-C02"
        },
          {
            "1": "Management of the guest operating systems",
            "2": "Maintenance of the configuration of infrastructure devices",
            "3": "Management of the host operating systems and virtualization",
            "4": "Maintenance of the software that powers Availability Zones",
            "5": "1",
            "no": "156",
            "question": "Which task is a customer’s responsibility, according to the AWS shared responsibility model?",
            "answer": "A",
            "examName": "AWS Certified Cloud Practitioner CLF-C02"
          },
          {
            "1": "Amazon DynamoDB",
            "2": "Amazon EC2 Spot Instances",
            "3": "AWS Snow Family",
            "4": "Amazon EC2 Auto Scaling",
            "5": "1",
            "no": "157",
            "question": "A company wants to automatically add and remove Amazon EC2 instances. The company wants the EC2 instances to adjust to varying workloads dynamically.\nWhich service or feature will meet these requirements?",
            "answer": "D",
            "examName": "AWS Certified Cloud Practitioner CLF-C02"
          },
          {
            "1": "AWS CloudHSM",
            "2": "AWS Key Management Service (AWS KMS)",
            "3": "AWS Secrets Manager",
            "4": "Server-side encryption",
            "5": "1",
            "no": "158",
            "question": "A user wants to securely automate the management and rotation of credentials that are shared between applications, while spending the least amount of time on managing tasks.\nWhich AWS service or feature can be used to accomplish this?",
            "answer": "C",
            "examName": "AWS Certified Cloud Practitioner CLF-C02"
          },
          {
            "1": "Amazon GuardDuty",
            "2": "Amazon Macie",
            "3": "Amazon Inspector",
            "4": "AWS Shield",
            "5": "1",
            "no": "159",
            "question": "Which security service automatically recognizes and classifies sensitive data or intellectual property on AWS?",
            "answer": "B",
            "examName": "AWS Certified Cloud Practitioner CLF-C02"
          },
          {
            "1": "Share root user credentials with team members.",
            "2": "Create multiple root users for the account, separated by environment.",
            "3": "Enable multi-factor authentication (MFA) on the root user.",
            "4": "Create an IAM user with administrator privileges for daily administrative tasks, instead of using the root user.",
            "5": "Use programmatic access instead of the root user and password.",
            "no": "160",
            "question": "Which actions are best practices for an AWS account root user? (Choose two.)",
            "answer": "CD",
            "examName": "AWS Certified Cloud Practitioner CLF-C02"
          },
          {
              "1": "Create a read replica of the DB instance.",
              "2": "Create a template of the DB instance by using AWS CloudFormation.",
              "3": "Take frequent snapshots of the DB instance. Store the snapshots in Amazon S3.",
              "4": "Modify the DB instance to be a Multi-AZ deployment.",
              "5": "1",
              "no": "161",
              "question": "A company is running a critical workload on an Amazon RDS DB instance. The company needs the DB instance to be highly available with a recovery time of less than 5 minutes.Which solution will meet these requirements?",
              "answer": "D",
              "examName": "AWS Certified Cloud Practitioner CLF-C02"
            },
            {
              "1": "Reserved Instances",
              "2": "Spot Instances",
              "3": "On-Demand Instances",
              "4": "Dedicated Hosts",
              "5": "1",
              "no": "162",
              "question": "A company plans to migrate its application to AWS and run the application on Amazon EC2 instances. The application will have continuous usage for 1 year.Which EC2 instance purchasing option will meet these requirements MOST cost-effectively?",
              "answer": "A",
              "examName": "AWS Certified Cloud Practitioner CLF-C02"
            },
            {
              "1": "The company",
              "2": "AWS",
              "3": "Firewall vendor",
              "4": "AWS Marketplace partner",
              "5": "1",
              "no": "163",
              "question": "A company needs to transfer data between an Amazon S3 bucket and an on-premises application.Who is responsible for the security of this data, according to the AWS shared responsibility model?",
              "answer": "A",
              "examName": "AWS Certified Cloud Practitioner CLF-C02"
            },
            {
              "1": "Security",
              "2": "Reliability",
              "3": "Performance efficiency",
              "4": "Cost optimization",
              "5": "1",
              "no": "164",
              "question": "Which pillar of the AWS Well-Architected Framework refers to the ability of a system to recover from infrastructure or service disruptions and dynamically acquire computing resources to meet demand?",
              "answer": "B",
              "examName": "AWS Certified Cloud Practitioner CLF-C02"
            },
            {
              "1": "AWS Lake Formation",
              "2": "IAM credential report",
              "3": "Amazon CloudWatch",
              "4": "IAM Access Analyzer",
              "5": "1",
              "no": "165",
              "question": "A company wants to identify Amazon S3 buckets that are shared with another AWS account.Which AWS service or feature will meet these requirements?",
              "answer": "D",
              "examName": "AWS Certified Cloud Practitioner CLF-C02"
            },
            {
              "1": "Amazon Athena",
              "2": "Amazon Kendra",
              "3": "Amazon QuickSight",
              "4": "Amazon Redshift",
              "5": "1",
              "no": "166",
              "question": "Which AWS service gives users the ability to build interactive business intelligence dashboards that include machine learning insights?",
              "answer": "C",
              "examName": "AWS Certified Cloud Practitioner CLF-C02"
            },
            {
              "1": "Speed of innovation",
              "2": "Resource elasticity",
              "3": "Decoupled architecture",
              "4": "Global deployment",
              "5": "1",
              "no": "167",
              "question": "Which of the following is an AWS value proposition that describes a user’s ability to scale infrastructure based on demand?",
              "answer": "B",
              "examName": "AWS Certified Cloud Practitioner CLF-C02"
            },
            {
              "1": "Enable S3 Cross-Region Replication (CRR) on the S3 bucket.",
              "2": "Use IAM roles for applications that require access to the S3 bucket.",
              "3": "Configure AWS WAF to prevent unauthorized access to the S3 bucket.",
              "4": "Configure Amazon GuardDuty to prevent unauthorized access to the S3 bucket.",
              "5": "1",
              "no": "168",
              "question": "Which action is a security best practice for access to sensitive data that is stored in an Amazon S3 bucket?",
              "answer": "B",
              "examName": "AWS Certified Cloud Practitioner CLF-C02"
            },
          {
            "1": "The ability the ensure high availability by deploying workloads to multiple regions",
            "2": "A pay-as-you-go model for many services and resources",
            "3": "The ability to transfer infrastructure management to the AWS Cloud",
            "4": "The ability to provision and deprovision resources quickly with minimal effort",
            "5": "1",
            "no": "169",
            "question": "A company wants to know more about the benefits offered by cloud computing. The company wants to understand the operational advantage of agility. How does AWS provide agility for users?",
            "answer": "D",
            "examName": "AWS Certified Cloud Practitioner CLF-C02"
          },
            {
              "1": "AWS Identity and Access Management (IAM)",
              "2": "Amazon Cognito",
              "3": "AWS IAM Identity Center (AWS Single Sign-On)",
              "4": "AWS CLI",
              "5": "1",
              "no": "170",
              "question": "A company needs a central user portal so that users can log in to third-party business applications that support Security Assertion Markup Language (SAML) 2.0.Which AWS service will meet this requirement?",
              "answer": "B",
              "examName": "AWS Certified Cloud Practitioner CLF-C02"
            },
{
    "1": "Amazon EventBridge",
    "2": "AWS Service Catalog",
    "3": "AWS Control Tower",
    "4": "AWS Health Dashboard",
    "5": "1",
    "no": "171",
    "question": "Which AWS service should users use to learn about AWS service availability and operations?",
    "answer": "D",
    "examName": "AWS Certified Cloud Practitioner CLF-C02"
  },
    {
      "1": "VPC Flow Logs",
      "2": "Amazon Inspector",
      "3": "VPC endpoint services",
      "4": "NAT gateway",
      "5": "1",
      "no": "172",
      "question": "Which AWS service or tool can be used to capture information about inbound and outbound traffic in an Amazon VPC?",
      "answer": "A",
      "examName": "AWS Certified Cloud Practitioner CLF-C02"
    },
  {
      "1": "Software licenses",
      "2": "Networking",
      "3": "Customer data",
      "4": "Encryption keys",
      "5": "1",
      "no": "173",
      "question": "What is the customer ALWAYS responsible for managing, according to the AWS shared responsibility model?",
      "answer": "C",
      "examName": "AWS Certified Cloud Practitioner CLF-C02"
    },
      {
        "1": "AWS Secrets Manager",
        "2": "AWS Artifact",
        "3": "AWS Security Hub",
        "4": "AWS Certificate Manager",
        "5": "1",
        "no": "174",
        "question": "Which AWS service can be used to retrieve compliance reports on demand?",
        "answer": "B",
        "examName": "AWS Certified Cloud Practitioner CLF-C02"
      },
    {
      "1": "AWS WAF",
      "2": "AWS Trusted Advisor",
      "3": "Amazon Inspector",
      "4": "AWS Shield",
      "5": "1",
      "no": "175",
      "question": "Which AWS service enables users to check for vulnerabilities on Amazon EC2 instances by using predefined assessment templates?",
      "answer": "C",
      "examName": "AWS Certified Cloud Practitioner CLF-C02"
    },
  {
    "1": "AWS DataSync",
    "2": "AWS Application Migration Service",
    "3": "AWS Application Discovery Service",
    "4": "AWS Database Migration Service (AWS DMS)",
    "5": "1",
    "no": "176",
    "question": "A company plans to migrate to the AWS Cloud. The company is gathering information about its on-premises infrastructure and requires information such as the hostname, IP address, and MAC address.Which AWS service will meet these requirements?",
    "answer": "C",
    "examName": "AWS Certified Cloud Practitioner CLF-C02"
  },
  {
      "1": "Enable programmatic access for all IAM users.",
      "2": "Use IAM users instead of IAM roles to delegate permissions.",
      "3": "Rotate access keys on a reoccurring basis.",
      "4": "Use inline policies instead of customer managed policies.",
      "5": "1",
      "no": "177",
      "question": "Which action will help increase security in the AWS Cloud?",
      "answer": "C",
      "examName": "AWS Certified Cloud Practitioner CLF-C02"
    },
  {
    "1": "AWS Cloud Adoption Framework (AWS CAF)",
    "2": "AWS Pricing Calculator",
    "3": "AWS Well-Architected Framework",
    "4": "AWS Budgets",
    "5": "1",
    "no": "178",
    "question": "A company is planning to migrate its application to the AWS Cloud.Which AWS tool or set of resources should the company use to analyze and assess its readiness for migration?",
    "answer": "A",
    "examName": "AWS Certified Cloud Practitioner CLF-C02"
  },
    {
      "1": "Amazon S3 is a high-performance block storage service that is designed for use with Amazon EC2.",
      "2": "Amazon S3 is an object storage service that provides high-level performance, security, scalability, and data availability.",
      "3": "Amazon S3 is a fully managed, highly reliable, and scalable file storage system that is accessible over the industry-standard SMB protocol.",
      "4": "Amazon S3 is a scalable, fully managed elastic NFS for use with AWS Cloud services and on-premises resources.",
      "5": "1",
      "no": "179",
      "question": "Which of the following describes some of the core functionality of Amazon S3?",
      "answer": "B",
      "examName": "AWS Certified Cloud Practitioner CLF-C02"
    },
      {
        "1": "High availability",
        "2": "Economies of scale",
        "3": "Pay-as-you-go pricing",
        "4": "Global reach",
        "5": "1",
        "no": "180",
        "question": "Which AWS benefit is demonstrated by on-demand technology services that enable companies to replace upfront fixed expenses with variable expenses?",
        "answer": "C",
        "examName": "AWS Certified Cloud Practitioner CLF-C02"
      },
  {
    "1": "AWS VPN",
    "2": "Elastic Load Balancing",
    "3": "AWS Direct Connect",
    "4": "VPC peering",
    "5": "Amazon CloudFront",
    "no": "181",
    "question": "Which AWS services or features enable users to connect on-premises networks to a VPC? (Choose two.)",
    "answer": "AC",
    "examName": "AWS Certified Cloud Practitioner CLF-C02"
  },
{
    "1": "Amazon RDS",
    "2": "Amazon DynamoDB",
    "3": "Amazon Aurora",
    "4": "Amazon Redshift",
    "5": "1",
    "no": "182",
    "question": "A user needs to quickly deploy a nonrelational database on AWS. The user does not want to manage the underlying hardware or the database software.Which AWS service can be used to accomplish this?",
    "answer": "B",
    "examName": "AWS Certified Cloud Practitioner CLF-C02"
  },
  {
    "1": "Switch from Amazon RDS to Amazon DynamoDB to accommodate NoSQL datasets.",
    "2": "Base the selection of Amazon EC2 instance types on past utilization patterns.",
    "3": "Use Amazon S3 Lifecycle policies to move objects that users access infrequently to lower-cost storage tiers.",
    "4": "Use Multi-AZ deployments for Amazon RDS.",
    "5": "Replace existing Amazon EC2 instances with AWS Elastic Beanstalk.",
    "no": "183",
    "question": "Which actions are examples of a company’s effort to rightsize its AWS resources to control cloud costs? (Choose two.)",
    "answer": "BC",
    "examName": "AWS Certified Cloud Practitioner CLF-C02"
  },
  {
    "1": "Network ACLs",
    "2": "Security groups",
    "3": "AWS Trusted Advisor",
    "4": "AWS WAF",
    "5": "1",
    "no": "184",
    "question": "Which AWS service or feature can a company use to apply security rules to specific Amazon EC2 instances?",
    "answer": "B",
    "examName": "AWS Certified Cloud Practitioner CLF-C02"
  },
  {
    "1": "Perform operations as code.",
    "2": "Enable traceability.",
    "3": "Automatically scale to meet demand.",
    "4": "Deploy resources globally to improve response time.",
    "5": "Automatically recover from failure.",
    "no": "185",
    "question": "Which design principles support the reliability pillar of the AWS Well-Architected Framework? (Choose two.)",
    "answer": "CE",
    "examName": "AWS Certified Cloud Practitioner CLF-C02"
  },
  {
      "1": "Inbound data transfer from the internet",
      "2": "Outbound data transfer to the internet",
      "3": "Data transfer between AWS Regions",
      "4": "Data transfer between Availability Zones",
      "5": "1",
      "no": "186",
      "question": "A company that uses AWS needs to transfer 2 TB of data.Which type of transfer of that data would result in no cost for the company?",
      "answer": "A",
      "examName": "AWS Certified Cloud Practitioner CLF-C02"
    },
  {
    "1": "AWS Marketplace",
    "2": "Amazon Machine Image (AMI)",
    "3": "AWS CloudFormation",
    "4": "AWS OpsWorks",
    "5": "1",
    "no": "187",
    "question": "A company wants to create templates that the company can reuse to deploy multiple AWS resources.Which AWS service or feature can the company use to meet this requirement?",
    "answer": "C",
    "examName": "AWS Certified Cloud Practitioner CLF-C02"
  },
        {
          "1": "AWS Step Functions",
          "2": "Amazon Simple Notification Service (Amazon SNS)",
          "3": "Amazon Kinesis Data Streams",
          "4": "Amazon Simple Queue Service (Amazon SQS)",
          "5": "1",
          "no": "188",
          "question": "A company is building an application that requires the ability to send, store, and receive messages between application components. The company has another requirement to process messages in first-in, first-out (FIFO) order.\n\nWhich AWS service should the company use?",
          "answer": "D",
          "examName": "AWS Certified Cloud Practitioner CLF-C02"
        },
  {
    "1": "AWS API",
    "2": "AWS Lightsail",
    "3": "AWS Cloud9",
    "4": "AWS CloudShell",
    "5": "1",
    "no": "189",
    "question": "Which AWS service or feature is a browser-based, pre-authenticated service that can be launched directly from the AWS Management Console?",
    "answer": "D",
    "examName": "AWS Certified Cloud Practitioner CLF-C02"
  },
  {
    "1": "Amazon Athena",
    "2": "Amazon RDS",
    "3": "Amazon EC2",
    "4": "Amazon DynamoDB",
    "5": "Amazon Aurora",
    "no": "190",
    "question": "A company wants to migrate its database to a managed AWS service that is compatible with PostgreSQL.Which AWS services will meet these requirements? (Choose two.)",
    "answer": "BE",
    "examName": "AWS Certified Cloud Practitioner CLF-C02"
  },
  {
    "1": "AWS IoT Core",
    "2": "Amazon Lightsail",
    "3": "AWS Storage Gateway",
    "4": "AWS Snowball Edge",
    "5": "1",
    "no": "191",
    "question": "A company has a fleet of cargo ships. The cargo ships have sensors that collect data at sea, where there is intermittent or no internet connectivity. The company needs to collect, format, and process the data at sea and move the data to AWS later.Which AWS service should the company use to meet these requirements?",
    "answer": "D",
    "examName": "AWS Certified Cloud Practitioner CLF-C02"
  },
    {
      "1": "AWS Certificate Manager (ACM)",
      "2": "IAM roles",
      "3": "AWS Security Hub",
      "4": "Amazon GuardDuty",
      "5": "1",
      "no": "192",
      "question": "A company hosts an application on multiple Amazon EC2 instances. The application uses Amazon Simple Notification Service (Amazon SNS) to send messages.Which AWS service or feature will give the application permission to access required AWS services?",
      "answer": "B",
      "examName": "AWS Certified Cloud Practitioner CLF-C02"
    },
    {
      "1": "AWS CloudFormation",
      "2": "AWS Elastic Beanstalk",
      "3": "Amazon EC2",
      "4": "AWS OpsWorks",
      "5": "1",
      "no": "193",
      "question": "A user has limited knowledge of AWS services, but wants to quickly deploy a scalable Node.js application in the AWS Cloud.Which service should be used to deploy the application?",
      "answer": "B",
      "examName": "AWS Certified Cloud Practitioner CLF-C02"
    },
      {
        "1": "Amazon CloudFront",
        "2": "Elastic Load Balancing",
        "3": "Amazon S3",
        "4": "Amazon Elastic Transcoder",
        "5": "1",
        "no": "194",
        "question": "A company needs a content delivery network that provides secure delivery of data, videos, applications, and APIs to users globally with low latency and high transfer speeds.Which AWS service meets these requirements?",
        "answer": "A",
        "examName": "AWS Certified Cloud Practitioner CLF-C02"
      },
        {
          "1": "AWS Resource Access Manager",
          "2": "AWS Managed Services",
          "3": "AWS License Manager",
          "4": "AWS Marketplace",
          "5": "1",
          "no": "195",
          "question": "A company needs to use third-party software for its workload on AWS.Which AWS service or feature can the company use to purchase the software?",
          "answer": "D",
          "examName": "AWS Certified Cloud Practitioner CLF-C02"
        },
  {
    "1": "Amazon S3",
    "2": "Amazon Elastic File System (Amazon EFS)",
    "3": "Amazon FSx for Windows File Server",
    "4": "Amazon Elastic Block Store (Amazon EBS)",
    "5": "1",
    "no": "196",
    "question": "A company needs fully managed, highly reliable, and scalable file storage that is accessible over the Server Message Block (SMB) protocol.Which AWS service will meet these requirements?",
    "answer": "C",
    "examName": "AWS Certified Cloud Practitioner CLF-C02"
  },
  {
    "1": "AWS Firewall Manager",
    "2": "Amazon GuardDuty",
    "3": "Amazon Detective",
    "4": "AWS WAF",
    "5": "1",
    "no": "197",
    "question": "A company needs to centrally configure and manage Amazon VPC security groups across multiple AWS accounts within an organization in AWS Organizations.Which AWS service should the company use to meet these requirements?",
    "answer": "A",
    "examName": "AWS Certified Cloud Practitioner CLF-C02"
  },
  {
    "1": "Configure identity and access management for applications.",
    "2": "Manage encryption options for data that is stored on AWS.",
    "3": "Configure security groups for Amazon EC2 instances.",
    "4": "Maintain the physical hardware of the infrastructure.",
    "5": "1",
    "no": "198",
    "question": "Which task is a responsibility of AWS, according to the AWS shared responsibility model?",
    "answer": "D",
    "examName": "AWS Certified Cloud Practitioner CLF-C02"
  },
  {
    "1": "VPC endpoint",
    "2": "NAT gateway",
    "3": "Amazon PrivateLink",
    "4": "VPC peering",
    "5": "1",
    "no": "199",
    "question": "A company has an Amazon EC2 instance in a private subnet. The company wants to initiate a connection to the internet to pull operating system updates while preventing traffic from the internet from accessing the EC2 instance.Which AWS managed service allows this?",
    "answer": "B",
    "examName": "AWS Certified Cloud Practitioner CLF-C02"
  },
    {
      "1": "Securing the virtualization layer",
      "2": "Patching the operating system on Amazon EC2 instances",
      "3": "Enforcing a strict password policy for IAM users",
      "4": "Patching the operating system on Amazon RDS instances",
      "5": "Configuring security groups and network ACLs",
      "no": "200",
      "question": "Which actions are the responsibility of AWS, according to the AWS shared responsibility model? (Choose two.)",
      "answer": "AD",
      "examName": "AWS Certified Cloud Practitioner CLF-C02"
    },
    {
      "1": "S3 Standard",
      "2": "S3 Glacier Flexible Retrieval",
      "3": "S3 One Zone-Infrequent Access (S3 One Zone-IA)",
      "4": "S3 Standard-Infrequent Access (S3 Standard-IA)",
      "5": "1",
      "no": "201",
      "question": "A company is storing data that will not be frequently accessed in the AWS Cloud. If the company needs to access the data, the data needs to be retrieved within 12 hours. The company wants a solution that is cost-effective for storage costs for each gigabyte.Which Amazon S3 storage class will meet these requirements?",
      "answer": "B",
      "examName": "AWS Certified Cloud Practitioner CLF-C02"
    },
    {
        "1": "Amazon S3 access control lists (ACLs)",
        "2": "AWS Certificate Manager (ACM)",
        "3": "Network Access Analyzer",
        "4": "AWS Identity and Access Management Access Analyzer",
        "5": "1",
        "no": "202",
        "question": "Which AWS service or resource can be used to identify services that have been used by a user within a specified date range?",
        "answer": "D",
        "examName": "AWS Certified Cloud Practitioner CLF-C02"
      },
    {
        "1": "AWS Support",
        "2": "AWS Organizations",
        "3": "AWS Service Catalog",
        "4": "AWS Partner Network (APN)",
        "5": "1",
        "no": "203",
        "question": "A company needs to engage third-party consultants to help maintain and support its AWS environment and the company’s business needs.Which AWS service or resource will meet these requirements?",
        "answer": "D",
        "examName": "AWS Certified Cloud Practitioner CLF-C02"
      },
      {
        "1": "AWS Budgets",
        "2": "AWS Cost Explorer",
        "3": "AWS Cost and Usage Report",
        "4": "AWS Cost Anomaly Detection",
        "5": "1",
        "no": "204",
        "question": "A company wants to create Amazon QuickSight dashboards every week by using its billing data.Which AWS feature or tool can the company use to meet these requirements?",
        "answer": "C",
        "examName": "AWS Certified Cloud Practitioner CLF-C02"
      },
      {
          "1": "AWS Storage Gateway",
          "2": "AWS Snowcone",
          "3": "AWS Backup",
          "4": "Amazon Elastic File System (Amazon EFS)",
          "5": "1",
          "no": "205",
          "question": "A company is planning to move data backups to the AWS Cloud. The company needs to replace on-premises storage with storage that is cloud-based but locally cached.Which AWS service meets these requirements?",
          "answer": "A",
          "examName": "AWS Certified Cloud Practitioner CLF-C02"
        },
  {
    "1": "Access the AWS Cost Management console to organize resources, set an AWS budget, and receive notifications of unintentional usage.",
    "2": "Use tags to organize the resources. Activate cost allocation tags to track AWS costs on a detailed level.",
    "3": "Create Amazon CloudWatch dashboards to visually organize and track costs individually.",
    "4": "Access the AWS Billing and Cost Management dashboard to organize and track resource consumption on a detailed level.",
    "5": "1",
    "no": "206",
    "question": "A company needs to organize its resources and track AWS costs on a detailed level. The company needs to categorize costs by business department, environment, and application.Which solution will meet these requirements?",
    "answer": "B",
    "examName": "AWS Certified Cloud Practitioner CLF-C02"
  },
        {
          "1": "AWS Step Functions",
          "2": "AWS Service Catalog",
          "3": "Amazon Simple Queue Service (Amazon SQS)",
          "4": "AWS Batch",
          "5": "1",
          "no": "207",
          "question": "A company needs to plan, schedule, and run hundreds of thousands of computing jobs on AWS.Which AWS service can the company use to meet this requirement?",
          "answer": "D",
          "examName": "AWS Certified Cloud Practitioner CLF-C02"
        },
                  {
                      "1": "Amazon Route 53",
                      "2": "Network Load Balancer",
                      "3": "Amazon S3 Transfer Acceleration",
                      "4": "AWS Global Accelerator",
                      "5": "Application Load Balancer",
                      "no": "208",
                      "question": "Which AWS services or features provide high availability and low latency by enabling failover across different AWS Regions? (Choose two.)",
                      "answer": "AD",
                      "examName": "AWS Certified Cloud Practitioner CLF-C02"
                    },
  {
    "1": "Scale the number of EC2 instances in or out automatically, based on demand.",
    "2": "Use serverless EC2 instances.",
    "3": "Scale the size of EC2 instances up or down automatically, based on demand.",
    "4": "Transfer unused CPU resources between EC2 instances.",
    "5": "1",
    "no": "209",
    "question": "Which of the following is a way to use Amazon EC2 Auto Scaling groups to scale capacity in the AWS Cloud?",
    "answer": "A",
    "examName": "AWS Certified Cloud Practitioner CLF-C02"
  },
        {
            "1": "Trade variable expenses for capital expenses.",
            "2": "Deploy globally in minutes.",
            "3": "Plan capacity in advance of deployments.",
            "4": "Take advantage of economies of scale.",
            "5": "Reduce dependencies on network connectivity.",
            "no": "210",
            "question": "Which abilities are benefits of the AWS Cloud? (Choose two.)",
            "answer": "BD",
            "examName": "AWS Certified Cloud Practitioner CLF-C02"
          },
          {
            "1": "Amazon Inspector",
            "2": "AWS Web Application Firewall (AWS WAF)",
            "3": "Elastic Load Balancing (ELB)",
            "4": "AWS Shield",
            "5": "1",
            "no": "211",
            "question": "Which AWS security service protects applications from distributed denial of service attacks with always-on detection and automatic inline mitigations?",
            "answer": "D",
            "examName": "AWS Certified Cloud Practitioner CLF-C02"
          },
            {
              "1": "AWS CloudFormation",
              "2": "AWS CodePipeline",
              "3": "AWS Cloud Development Kit (AWS CDK)",
              "4": "AWS Systems Manager",
              "5": "1",
              "no": "212",
              "question": "Which AWS service allows users to model and provision AWS resources using common programming languages?",
              "answer": "C",
              "examName": "AWS Certified Cloud Practitioner CLF-C02"
            },
              {
                "1": "Reserved Instances",
                "2": "On-Demand",
                "3": "Dedicated Hosts",
                "4": "Spot Instances",
                "5": "1",
                "no": "213",
                "question": "Which Amazon EC2 instance pricing model can provide discounts of up to 90%?",
                "answer": "D",
                "examName": "AWS Certified Cloud Practitioner CLF-C02"
              },
              {
                "1": "Network access control list",
                "2": "Security groups",
                "3": "AWS Trusted Advisor",
                "4": "Virtual private gateways",
                "5": "1",
                "no": "214",
                "question": "Which of the following acts as an instance-level firewall to control inbound and outbound access?",
                "answer": "B",
                "examName": "AWS Certified Cloud Practitioner CLF-C02"
              },
  {
    "1": "Stop guessing capacity",
    "2": "Trade fixed expense for variable expense",
    "3": "Achieve economies of scale",
    "4": "Increase speed and agility",
    "5": "1",
    "no": "215",
    "question": "A company must be able to develop, test, and launch an application in the AWS Cloud quickly.Which advantage of cloud computing will meet these requirements?",
    "answer": "D",
    "examName": "AWS Certified Cloud Practitioner CLF-C02"
  },
              {
                    "1": "IAM user groups",
                    "2": "IAM roles",
                    "3": "IAM instance profiles",
                    "4": "IAM policies for individual users",
                    "5": "1",
                    "no": "216",
                    "question": "A company has teams that have different job roles and responsibilities. The company’s employees often change teams. The company needs to manage permissions for the employees so that the permissions are appropriate for the job responsibilities.Which IAM resource should the company use to meet this requirement with the LEAST operational overhead?",
                    "answer": "B",
                    "examName": "AWS Certified Cloud Practitioner CLF-C02"
                  },
              {
                "1": "AWS Shield",
                "2": "AWS Secrets Manager",
                "3": "AWS Identity and Access Management (IAM)",
                "4": "Amazon Cognito",
                "5": "1",
                "no": "217",
                "question": "Which AWS service can a company use to securely store and encrypt passwords for a database?",
                "answer": "B",
                "examName": "AWS Certified Cloud Practitioner CLF-C02"
              },
              {
                "1": "AWS Certificate Manager",
                "2": "AWS Systems Manager",
                "3": "AWS Artifact",
                "4": "Amazon Inspector",
                "5": "1",
                "no": "218",
                "question": "What can a cloud practitioner use to retrieve AWS security and compliance documents and submit them as evidence to an auditor or regulator?",
                "answer": "C",
                "examName": "AWS Certified Cloud Practitioner CLF-C02"
              },
                {
                  "1": "Server-side encryption with Amazon S3 managed encryption keys (SSE-S3)",
                  "2": "Server-side encryption with AWS KMS managed keys (SSE-KMS)",
                  "3": "TLS",
                  "4": "SSL",
                  "5": "Transparent Data Encryption (TDE)",
                  "no": "219",
                  "question": "Which encryption types can be used to protect objects at rest in Amazon S3? (Choose two.)",
                  "answer": "AB",
                  "examName": "AWS Certified Cloud Practitioner CLF-C02"
                },
                  {
                    "1": "AWS Directory Service",
                    "2": "AWS Identity and Access Management (IAM)",
                    "3": "Amazon Cognito",
                    "4": "AWS IAM Identity Center (AWS Single Sign-On)",
                    "5": "1",
                    "no": "220",
                    "question": "A company wants to integrate its online shopping website with social media login credentials.Which AWS service can the company use to make this integration?",
                    "answer": "C",
                    "examName": "AWS Certified Cloud Practitioner CLF-C02"
                  },
              {
                "1": "AWS Shield",
                "2": "AWS Config",
                "3": "AWS IAM",
                "4": "Amazon Inspector",
                "5": "1",
                "no": "221",
                "question": "Which AWS service is used to track, record, and audit configuration changes made to AWS resources?",
                "answer": "B",
                "examName": "AWS Certified Cloud Practitioner CLF-C02"
              },
                {
                  "1": "3 hours, 5 minutes",
                  "2": "3 hours, 5 minutes, and 6 seconds",
                  "3": "3 hours, 6 minutes",
                  "4": "4 hours",
                  "5": "1",
                  "no": "222",
                  "question": "A customer runs an On-Demand Amazon Linux EC2 instance for 3 hours, 5 minutes, and 6 seconds.For how much time will the customer be billed?",
                  "answer": "B",
                  "examName": "AWS Certified Cloud Practitioner CLF-C02"
                },
                                {
                                  "1": "AWS Resource Access Manager",
                                  "2": "AWS Amplify",
                                  "3": "AWS Shield",
                                  "4": "Amazon GuardDuty",
                                  "5": "1",
                                  "no": "223",
                                  "question": "A company website is experiencing DDoS attacks.\nWhich AWS service can help protect the company website against these attacks?",
                                  "answer": "C",
                                  "examName": "AWS Certified Cloud Practitioner CLF-C02"
                                },
                  {
                    "1": "AWS Trusted Advisor",
                    "2": "Amazon Inspector",
                    "3": "AWS Control Tower",
                    "4": "Migration Evaluator",
                    "5": "1",
                    "no": "224",
                    "question": "A company wants a customized assessment of its current on-premises environment. The company wants to understand its projected running costs in the AWS Cloud.Which AWS service or tool will meet these requirements?",
                    "answer": "D",
                    "examName": "AWS Certified Cloud Practitioner CLF-C02"
                  },
                    {
                      "1": "AWS Organizations",
                      "2": "Cost Explorer",
                      "3": "AWS Budgets",
                      "4": "AWS Trusted Advisor",
                      "5": "1",
                      "no": "225",
                      "question": "A company that has multiple business units wants to centrally manage and govern its AWS Cloud environments. The company wants to automate the creation of AWS accounts, apply service control policies (SCPs), and simplify billing processes.Which AWS service or tool should the company use to meet these requirements?",
                      "answer": "A",
                      "examName": "AWS Certified Cloud Practitioner CLF-C02"
                    },
                  {
                      "1": "AWS Personal Health Dashboard",
                      "2": "AWS Systems Manager",
                      "3": "AWS Trusted Advisor",
                      "4": "AWS Service Health Dashboard",
                      "5": "AWS Service Catalog",
                      "no": "226",
                      "question": "A company is hosting an application in the AWS Cloud. The company wants to verify that underlying AWS services and general AWS infrastructure are operating normally.Which combination of AWS services can the company use to gather the required information? (Choose two.)",
                      "answer": "AD",
                      "examName": "AWS Certified Cloud Practitioner CLF-C02"
                    },
                    {
                      "1": "Cloud Adoption Readiness Tool",
                      "2": "AWS Migration Hub",
                      "3": "AWS Database Migration Service (AWS DMS)",
                      "4": "AWS Application Migration Service",
                      "5": "1",
                      "no": "227",
                      "question": "A company needs to migrate a PostgreSQL database from on-premises to Amazon RDS.Which AWS service or tool should the company use to meet this requirement?",
                      "answer": "C",
                      "examName": "AWS Certified Cloud Practitioner CLF-C02"
                    },
            {
                "1": "Security validation",
                "2": "Rightsizing",
                "3": "Elasticity",
                "4": "Global reach",
                "5": "1",
                "no": "228",
                "question": "Which cloud concept is demonstrated by using AWS Compute Optimizer?",
                "answer": "B",
                "examName": "AWS Certified Cloud Practitioner CLF-C02"
              },
                {
                  "1": "Amazon Inspector",
                  "2": "Amazon Macie",
                  "3": "AWS Identity and Access Management (IAM)",
                  "4": "Amazon CloudWatch",
                  "5": "1",
                  "no": "229",
                  "question": "A company hosts a large amount of data in AWS. The company wants to identify if any of the data should be considered sensitive.Which AWS service will meet the requirement?",
                  "answer": "B",
                  "examName": "AWS Certified Cloud Practitioner CLF-C02"
                },
                                  {
                                    "1": "On-Demand Instances",
                                    "2": "Reserved Instances",
                                    "3": "Dedicated Instances",
                                    "4": "Spot Instances",
                                    "5": "1",
                                    "no": "230",
                                    "question": "A user has a stateful workload that will run on Amazon EC2 for the next 3 years.What is the MOST cost-effective pricing model for this workload?",
                                    "answer": "B",
                                    "examName": "AWS Certified Cloud Practitioner CLF-C02"
                                  },
              {
                "1": "AWS Support",
                "2": "AWS customers",
                "3": "AWS Key Management Service (AWS KMS)",
                "4": "AWS Trusted Advisor",
                "5": "1",
                "no": "231",
                "question": "Who enables encryption of data at rest for Amazon Elastic Block Store (Amazon EBS)?",
                "answer": "B",
                "examName": "AWS Certified Cloud Practitioner CLF-C02"
              },
                            {
                              "1": "Generate an IAM user credentials report.",
                              "2": "Record API calls made to AWS services.",
                              "3": "Assess the compliance of AWS resource configurations with policies and guidelines.",
                              "4": "Ensure that Amazon EC2 instances are patched with the latest security updates.",
                              "5": "1",
                              "no": "232",
                              "question": "What can a user accomplish using AWS CloudTrail?",
                              "answer": "B",
                              "examName": "AWS Certified Cloud Practitioner CLF-C02"
                            },
                            {
                              "1": "Amazon DynamoDB",
                              "2": "Amazon S3",
                              "3": "Amazon EC2",
                              "4": "Amazon Aurora",
                              "5": "1",
                              "no": "233",
                              "question": "A company is planning to host its workloads on AWS.\nWhich AWS service requires the company to update and patch the guest operating system?",
                              "answer": "C",
                              "examName": "AWS Certified Cloud Practitioner CLF-C02"
                            },
              {
                "1": "Amazon OpenSearch Service",
                "2": "AWS Control Tower",
                "3": "AWS IAM Access Analyzer",
                "4": "AWS Fargate",
                "5": "1",
                "no": "234",
                "question": "Which AWS service or feature will search for and identify AWS resources that are shared externally?",
                "answer": "C",
                "examName": "AWS Certified Cloud Practitioner CLF-C02"
              },
              {
                "1": "Amazon DynamoDB",
                "2": "Amazon EC2",
                "3": "AWS Lambda",
                "4": "Amazon RDS",
                "5": "1",
                "no": "235",
                "question": "A company is migrating its workloads to the AWS Cloud. The company must retain full control of patch management for the guest operating systems that host its applications.\n\nWhich AWS service should the company use to meet these requirements?",
                "answer": "B",
                "examName": "AWS Certified Cloud Practitioner CLF-C02"
              },
            {
              "1": "Basic Support",
              "2": "Developer Support",
              "3": "Business Support",
              "4": "Enterprise Support",
              "5": "1",
              "no": "236",
              "question": "At what support level do users receive access to a support concierge?",
              "answer": "D",
              "examName": "AWS Certified Cloud Practitioner CLF-C02"
            },
                        {
                          "1": "AWS Lambda",
                          "2": "AWS Batch",
                          "3": "AWS Application Composer",
                          "4": "AWS App Runner",
                          "5": "1",
                          "no": "237",
                          "question": "Which AWS service can a company use to visually design and build serverless applications?",
                          "answer": "C",
                          "examName": "AWS Certified Cloud Practitioner CLF-C02"
                        },
              {
                "1": "AWS Partner Solutions Finder",
                "2": "AWS Support Center",
                "3": "AWS Management Console",
                "4": "AWS Marketplace",
                "5": "1",
                "no": "238",
                "question": "A company wants to migrate to AWS and use the same security software it uses on premises. The security software vendor offers its security software as a service on AWS.Where can the company purchase the security solution?",
                "answer": "D",
                "examName": "AWS Certified Cloud Practitioner CLF-C02"
              },
              {
                  "1": "Managing and encrypting application data",
                  "2": "Installing updates and security patches of guest operating system",
                  "3": "Configuration of infrastructure devices",
                  "4": "Configuration of security groups on each instance",
                  "5": "1",
                  "no": "239",
                  "question": "A company has deployed an Amazon EC2 instance.Which option is an AWS responsibility under the AWS shared responsibility model?",
                  "answer": "C",
                  "examName": "AWS Certified Cloud Practitioner CLF-C02"
                },
                  {
                    "1": "PostgreSQL on Amazon EC2",
                    "2": "Amazon RDS for PostgreSQL",
                    "3": "Amazon Aurora PostgreSQL-Compatible Edition",
                    "4": "Amazon Aurora Serverless",
                    "5": "1",
                    "no": "240",
                    "question": "A company wants to migrate its PostgreSQL database to AWS. The company does not use the database frequently.Which AWS service or resource will meet these requirements with the LEAST management overhead?",
                    "answer": "D",
                    "examName": "AWS Certified Cloud Practitioner CLF-C02"
                  },
                  {
                      "1": "Classify data.",
                      "2": "Configure access permissions.",
                      "3": "Manage encryption options.",
                      "4": "Provide public endpoints to store and retrieve data.",
                      "5": "Manage the infrastructure layer and the operating system.",
                      "no": "241",
                      "question": "A company is using Amazon DynamoDB for its application database.Which tasks are the responsibility of AWS, according to the AWS shared responsibility model? (Choose two.)",
                      "answer": "DE",
                      "examName": "AWS Certified Cloud Practitioner CLF-C02"
                    },
               {
                      "1": "Amazon EC2",
                      "2": "Amazon VPC",
                      "3": "Amazon Route 53",
                      "4": "Amazon RDS",
                      "5": "1",
                      "no": "242",
                      "question": "A company wants to create a globally accessible ecommerce platform for its customers. The company wants to use a highly available and scalable DNS web service to connect users to the platform.\n\nWhich AWS service will meet these requirements?",
                      "answer": "C",
                      "examName": "AWS Certified Cloud Practitioner CLF-C02"
                    },
  {
    "1": "Physical connectivity among Availability Zones",
    "2": "Network switch maintenance",
    "3": "Hardware updates and firmware patches",
    "4": "Amazon EC2 updates and security patches",
    "5": "1",
    "no": "243",
    "question": "Which maintenance task is the customer’s responsibility, according to the AWS shared responsibility model?",
    "answer": "D",
    "examName": "AWS Certified Cloud Practitioner CLF-C02"
  },
                      {
                        "1": "AWS WAF",
                        "2": "Amazon Detective",
                        "3": "Amazon CloudWatch",
                        "4": "AWS CloudTrail",
                        "5": "1",
                        "no": "244",
                        "question": "A company wants to improve its security posture by reviewing user activity through API calls.Which AWS service will meet this requirement?",
                        "answer": "D",
                        "examName": "AWS Certified Cloud Practitioner CLF-C02"
                      },
{
                        "1": "Use Savings Plans for a 3-year term.",
                        "2": "Use Dedicated Hosts.",
                        "3": "Buy Reserved Instances.",
                        "4": "Use On-Demand Instances.",
                        "5": "1",
                        "no": "245",
                        "question": "A company is migrating to the AWS Cloud and plans to run experimental workloads for 3 to 6 months on AWS.\nWhich pricing model will meet these requirements?",
                        "answer": "D",
                        "examName": "AWS Certified Cloud Practitioner CLF-C02"
                      },
            {
              "1": "Replace the EC2 instances with AWS Lambda functions.",
              "2": "Use AWS Infrastructure Event Management (IEM) support.",
              "3": "Submit a request on AWS Marketplace to monitor the event.",
              "4": "Review the coverage reports in the AWS Cost Management console.",
              "5": "1",
              "no": "246",
              "question": "A company that has AWS Enterprise Support is launching a new version of a popular product in 2 months. The company expects a large increase in traffic to its website. The website is hosted on Amazon EC2 instances.Which action should the company take to assess its readiness to scale for this launch?",
              "answer": "B",
              "examName": "AWS Certified Cloud Practitioner CLF-C02"
            },
            {
              "1": "Use AWS Organizations and create one account for each business unit.",
              "2": "Use a spreadsheet to control the owners and cost of each resource.",
              "3": "Use an Amazon DynamoDB table to record costs for each business unit.",
              "4": "Use the AWS Billing console to assign owners to resources and track costs.",
              "5": "1",
              "no": "247",
              "question": "A company wants to launch multiple workloads on AWS. Each workload is related to a different business unit. The company wants to separate and track costs for each business unit.\nWhich solution will meet these requirements with the LEAST operational overhead?",
              "answer": "A",
              "examName": "AWS Certified Cloud Practitioner CLF-C02"
            },
            {
              "1": "Amazon Neptune",
              "2": "Amazon Timestream",
              "3": "Amazon Forecast",
              "4": "Amazon DocumentDB (with MongoDB compatibility)",
              "5": "1",
              "no": "248",
              "question": "A company wants a time-series database service that makes it easier to store and analyze trillions of events each day.\n\nWhich AWS service will meet this requirement?",
              "answer": "B",
              "examName": "AWS Certified Cloud Practitioner CLF-C02"
            },
  {
    "1": "Configuration management",
    "2": "Physical and environmental controls",
    "3": "Data integrity authentication",
    "4": "Identity and access management",
    "5": "1",
    "no": "249",
    "question": "Which option is a shared control between AWS and the customer, according to the AWS shared responsibility model?",
    "answer": "A",
    "examName": "AWS Certified Cloud Practitioner CLF-C02"
  },
                                  {
                                    "1": "Spot Instances",
                                    "2": "Dedicated Instances",
                                    "3": "Reserved Instances",
                                    "4": "On-Demand Instances",
                                    "5": "1",
                                    "no": "250",
                                    "question": "A company often does not use all of its current Amazon EC2 capacity to run stateless workloads. The company wants to optimize its EC2 costs.Which EC2 instance type will meet these requirements?",
                                    "answer": "A",
                                    "examName": "AWS Certified Cloud Practitioner CLF-C02"
                                  },
              {
                "1": "S3 Standard",
                "2": "S3 Intelligent-Tiering",
                "3": "S3 Standard-Infrequent Access (S3 Standard-IA)",
                "4": "S3 One Zone-Infrequent Access (S3 One Zone-IA)",
                "5": "1",
                "no": "251",
                "question": "A company wants to store data in Amazon S3. The company rarely access the data, and the data can be regenerated if necessary. The company wants to store the data in the most cost-effective storage class.Which S3 storage class will meet this requirement?",
                "answer": "D",
                "examName": "AWS Certified Cloud Practitioner CLF-C02"
              },
//  {
//    "1": "AWS CodeBuild",
//    "2": "AWS CloudFormation",
//    "3": "AWS CLI",
//    "4": "AWS Cloud Development Kit (AWS CDK)",
//    "5": "1",
//    "no": "253",
//    "question": "A company wants to provision and manage its AWS infrastructure by using the common programming languages Typescript, Python, Java, and .NET.Which AWS service will meet this requirement?",
//    "answer": "D",
//    "examName": "AWS Certified Cloud Practitioner CLF-C02"
//  },
//    {
//      "1": "On-Demand Instances",
//      "2": "Convertible Reserved Instances",
//      "3": "Spot Instances",
//      "4": "Standard Reserved Instances",
//      "5": "1",
//      "no": "254",
//      "question": "Which Amazon EC2 pricing model provides the MOST cost savings for an always-up, right-sized database server running for a project that will last 1 year?",
//      "answer": "D",
//      "examName": "AWS Certified Cloud Practitioner CLF-C02"
//    },
//  {
//    "1": "The company will be charged the standard pay-as-you-go service rates for the usage that exceeds the Free Tier usage.",
//    "2": "AWS Support will contact the company to set up standard service charges.",
//    "3": "The company will be charged for the services it consumed during the Free Tier period, plus additional charges for service consumption after the Free Tier period.",
//    "4": "The company's AWS account will be frozen and can be restarted after a payment plan is established.",
//    "5": "1",
//    "no": "256",
//    "question": "A company is using the AWS Free Tier for several AWS services for an application.What will happen if the Free Tier usage period expires or if the application use exceeds the Free Tier usage limits?",
//    "answer": "A",
//    "examName": "AWS Certified Cloud Practitioner CLF-C02"
//  },
//      {
//        "1": "Business",
//        "2": "Governance",
//        "3": "Platform",
//        "4": "Operations",
//        "5": "1",
//        "no": "257",
//        "question": "A company wants to monitor its workload performance. The company wants to ensure that the cloud services are delivered at a level that meets its business needs.Which AWS Cloud Adoption Framework (AWS CAF) perspective will meet these requirements?",
//        "answer": "D",
//        "examName": "AWS Certified Cloud Practitioner CLF-C02"
//      },
//      {
//          "1": "AWS Compute Optimizer",
//          "2": "AWS Cost Explorer",
//          "3": "AWS Systems Manager Agent (SSM Agent)",
//          "4": "Migration Evaluator",
//          "5": "1",
//          "no": "259",
//          "question": "A company need an AWS service that provides a clear baseline of what the company runs in its on-premises data centers. The company needs the projected cost to run its on-premises workloads in the AWS Cloud.What AWS service or tool will meet these requirements?",
//          "answer": "D",
//          "examName": "AWS Certified Cloud Practitioner CLF-C02"
//        },
//        {
//          "1": "AWS Systems Manager",
//          "2": "AWS Organizations",
//          "3": "AWS License Manager",
//          "4": "Cost Explorer",
//          "5": "1",
//          "no": "260",
//          "question": "A company acquired another corporation. The company now has two AWS accounts.Which AWS service or tool can the company use to consolidate the billing for these two accounts?",
//          "answer": "B",
//          "examName": "AWS Certified Cloud Practitioner CLF-C02"
//        },
//        {
//          "1": "Performance efficiency",
//          "2": "Sustainability",
//          "3": "Reliability",
//          "4": "Security",
//          "5": "1",
//          "no": "261",
//          "question": "A company wants to set up its workloads to perform their intended functions and recover quickly from failure.Which pillar of the AWS Well-Architected Framework aligns with these goals?",
//          "answer": "C",
//          "examName": "AWS Certified Cloud Practitioner CLF-C02"
//        },
//        {
//          "1": "Amazon Athena",
//          "2": "AWS Glue",
//          "3": "Amazon S3",
//          "4": "AWS Snowball Edge",
//          "5": "1",
//          "no": "262",
//          "question": "Which of the following is a managed AWS service that is used specifically for extract, transform, and load (ETL) data?",
//          "answer": "B",
//          "examName": "AWS Certified Cloud Practitioner CLF-C02"
//        },
//  {
//    "1": "Amazon EventBridge",
//    "2": "Compute Savings Plans",
//    "3": "AWS Budgets",
//    "4": "Migration Evaluator",
//    "5": "1",
//    "no": "264",
//    "question": "A company wants to receive alerts to monitor its overall operating costs for its AWS public cloud infrastructure.Which AWS offering will meet these requirements?",
//    "answer": "C",
//    "examName": "AWS Certified Cloud Practitioner CLF-C02"
//  },
//{
//    "1": "Supporting application development",
//    "2": "Providing architecture guidance",
//    "3": "Answering billing and account inquiries",
//    "4": "Answering questions regarding technical support cases",
//    "5": "1",
//    "no": "265",
//    "question": "How does the AWS Enterprise Support Concierge team help users?",
//    "answer": "C",
//    "examName": "AWS Certified Cloud Practitioner CLF-C02"
//  },
//  {
//    "1": "Spot Instances",
//    "2": "Reserved Instances",
//    "3": "Dedicated Hosts",
//    "4": "On-Demand Instances",
//    "5": "1",
//    "no": "266",
//    "question": "A company wants to run a simulation for 3 years without interruptions.Which Amazon EC2 instance purchasing option will meet these requirements MOST cost-effectively?",
//    "answer": "B",
//    "examName": "AWS Certified Cloud Practitioner CLF-C02"
//  },
//  {
//    "1": "Amazon Detective",
//    "2": "AWS Pricing Calculator",
//    "3": "Savings Plans",
//    "4": "Basic Support",
//    "5": "1",
//    "no": "267",
//    "question": "Which AWS service or resource can provide discounts on some AWS service costs in exchange for a spending commitment?",
//    "answer": "C",
//    "examName": "AWS Certified Cloud Practitioner CLF-C02"
//  },
//  {
//    "1": "High availability",
//    "2": "Performance efficiency",
//    "3": "Cost optimization",
//    "4": "Going global in minutes",
//    "5": "Continuous development",
//    "no": "268",
//    "question": "Which of the following are pillars of the AWS Well-Architected Framework? (Choose two.)",
//    "answer": "B",
//    "examName": "AWS Certified Cloud Practitioner CLF-C02"
//  },
//  {
//    "1": "AWS Outposts",
//    "2": "AWS Transfer Family",
//    "3": "AWS Snow Family",
//    "4": "AWS Migration Hub",
//    "5": "1",
//    "no": "270",
//    "question": "A team of researchers is going to collect data at remote locations around the world. Many locations do not have internet connectivity. The team needs to capture the data in the field, and transfer it to the AWS Cloud later.Which AWS service will support these requirements?",
//    "answer": "C",
//    "examName": "AWS Certified Cloud Practitioner CLF-C02"
//  },
//    {
//      "1": "S3 Versioning",
//      "2": "S3 Lifecycle",
//      "3": "S3 Object Lock",
//      "4": "S3 Inventory",
//      "5": "1",
//      "no": "274",
//      "question": "A company wants to save costs by archiving data that is no longer frequently accessed by end users.Which Amazon S3 feature will meet this requirement?",
//      "answer": "B",
//      "examName": "AWS Certified Cloud Practitioner CLF-C02"
//    },
//    {
//      "1": "AWS Database Migration Service (AWS DMS)",
//      "2": "AWS DataSync",
//      "3": "AWS Backup",
//      "4": "AWS Snowball Edge",
//      "5": "1",
//      "no": "276",
//      "question": "A company wants an AWS service to collect and process 10 TB of data locally and transfer the data to AWS. The company has intermittent connectivity.Which AWS service will meet these requirements?",
//      "answer": "D",
//      "examName": "AWS Certified Cloud Practitioner CLF-C02"
//    },
//    {
//      "1": "Go global in minutes.",
//      "2": "Make frequent, small, reversible changes.",
//      "3": "Implement a strong foundation of identity and access management",
//      "4": "Stop spending money on hardware infrastructure for data center operations.",
//      "5": "1",
//      "no": "277",
//      "question": "Which of the following is an AWS Well-Architected Framework design principle for operational excellence in the AWS Cloud?",
//      "answer": "B",
//      "examName": "AWS Certified Cloud Practitioner CLF-C02"
//    },
//  {
//    "1": "IAM policies",
//    "2": "IAM user groups",
//    "3": "AWS Security Token Service (AWS STS)",
//    "4": "AWS IAM Identity Center (AWS Single Sign-On)",
//    "5": "1",
//    "no": "279",
//    "question": "A developer wants AWS users to access AWS services by using temporary security credentials.Which AWS service or feature should the developer use to provide these credentials?",
//    "answer": "C",
//    "examName": "AWS Certified Cloud Practitioner CLF-C02"
//  },
//  {
//    "1": "Create an IAM user account for the auditor, granting the auditor administrator permissions.",
//    "2": "Take a screenshot of each user's page in the AWS Management Console, then provide the screenshots to the auditor.",
//    "3": "Download the IAM credential report, then provide the report to the auditor.",
//    "4": "Download the AWS Trusted Advisor report, then provide the report to the auditor.",
//    "5": "1",
//    "no": "288",
//    "question": "An external auditor has requested that a company provide a list of all its IAM users, including the status of users' credentials and access keys.What is the SIMPLEST way to provide this information?",
//    "answer": "C",
//    "examName": "AWS Certified Cloud Practitioner CLF-C02"
//  },
//  {
//    "1": "Performance and capacity management",
//    "2": "Data engineering",
//    "3": "Continuous integration and continuous delivery (CI/CD)",
//    "4": "Infrastructure protection",
//    "5": "Change and release management",
//    "no": "292",
//    "question": "Which capabilities are in the platform perspective of the AWS Cloud Adoption Framework (AWS CAF)? (Choose two.)",
//    "answer": "BC",
//    "examName": "AWS Certified Cloud Practitioner CLF-C02"
//  },
//  {
//    "1": "High availability",
//    "2": "Decoupled architecture",
//    "3": "Resilience",
//    "4": "Elasticity",
//    "5": "1",
//    "no": "347",
//    "question": "Which architecture concept describes the ability to deploy resources on demand and release resources when they are no longer needed?",
//    "answer": "D",
//    "examName": "AWS Certified Cloud Practitioner CLF-C02"
//  },
//    {
//      "1": "The deletion of IAM users",
//      "2": "The deletion of an AWS account",
//      "3": "The creation of an organization in AWS Organizations",
//      "4": "The deletion of Amazon EC2 instances",
//      "5": "1",
//      "no": "348",
//      "question": "Which task requires a user to sign in as the AWS account root user?",
//      "answer": "B",
//      "examName": "AWS Certified Cloud Practitioner CLF-C02"
//    },
//      {
//        "1": "Spot Instances",
//        "2": "Convertible Reserved Instances",
//        "3": "Standard Reserved Instances",
//        "4": "Dedicated Hosts",
//        "5": "1",
//        "no": "350",
//        "question": "A company needs Amazon EC2 instances for a workload that can tolerate interruptions.Which EC2 instance purchasing option meets this requirement with the LARGEST discount compared to On-Demand prices?",
//        "answer": "A",
//        "examName": "AWS Certified Cloud Practitioner CLF-C02"
//      },
//        {
//          "1": "Internet gateway",
//          "2": "NAT gateway",
//          "3": "AWS WAF",
//          "4": "VPC peering",
//          "5": "1",
//          "no": "352",
//          "question": "Which AWS service or component allows inbound traffic from the internet to access a VPC?",
//          "answer": "A",
//          "examName": "AWS Certified Cloud Practitioner CLF-C02"
//        },
//  {
//    "1": "Amazon Elastic Kubernetes Service (Amazon EKS)",
//    "2": "AWS Outposts",
//    "3": "AWS CodePipeline",
//    "4": "AWS CloudFormation",
//    "5": "1",
//    "no": "353",
//    "question": "Which AWS service can companies use to create infrastructure from code?",
//    "answer": "D",
//    "examName": "AWS Certified Cloud Practitioner CLF-C02"
//  },
//  {
//      "1": "AWS replaces upfront capital expenditures with pay-as-you-go costs.",
//      "2": "AWS is designed for high availability, which eliminates user downtime.",
//      "3": "AWS eliminates the need for on-premises IT staff.",
//      "4": "AWS uses economies of scale to continually reduce prices.",
//      "5": "AWS offers a single pricing model for Amazon EC2 instances.",
//      "no": "359",
//      "question": "In which ways does the AWS Cloud offer lower total cost of ownership (TCO) of computing resources than on-premises data centers? (Choose two.)",
//      "answer": "AD",
//      "examName": "AWS Certified Cloud Practitioner CLF-C02"
//    },
//
//  {
//    "1": "AWS Local Zones",
//    "2": "Availability Zones",
//    "3": "AWS Outposts",
//    "4": "AWS Wavelength Zones",
//    "5": "1",
//    "no": "360",
//    "question": "A company wants to deploy some of its resources in the AWS Cloud. To meet regulatory requirements, the data must remain local and on premises. There must be low latency between AWS and the company resources.Which AWS service or feature can be used to meet these requirements?",
//    "answer": "C",
//    "examName": "AWS Certified Cloud Practitioner CLF-C02"
//  },
//      {
//        "1": "AWS Config",
//        "2": "AWS Cost Anomaly Detection",
//        "3": "AWS Budgets",
//        "4": "AWS Compute Optimizer",
//        "5": "1",
//        "no": "399",
//        "question": "A company has deployed a web application to Amazon EC2 instances. The EC2 instances have low usage.Which AWS service or feature should the company use to rightsize the EC2 instances?",
//        "answer": "D",
//        "examName": "AWS Certified Cloud Practitioner CLF-C02"
//      },

  ]