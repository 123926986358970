import React from 'react'

const Footer = () => {
  return (
    <div className='h-max w-full max-w-full px-3 py-16 md:py-12 sm:py-8 bg-[#010C1C] flex justify-center items-center flex-col flex-nowrap text-white'>

        <div className='h-max w-full max-w-full m-2 px-2 pt-4 pb-6 flex justify-around items-center flex-row flex-wrap border-b-2 border-[#FFFFFF26]'>

            <div className='h-max w-[360px] max-w-full m-1 p-2 flex justify-center items-start flex-col flex-nowrap gap-3'>
                <h1 className='h-max w-full font-manrope font-extrabold text-6xl lg:text-5xl md:text-4xl sm:text-3xl'>AceExams</h1>
                <h1 className='h-max w-full max-w-full font-mulish text-base md:text-sm sm:text-xs'>We are a comprehensive and constantly updated website for IT certification exam materials.</h1>
                <h1 className='h-max w-full max-w-full font-mulish text-base md:text-sm sm:text-xs'>Using our own resources, we are committed to strengthening the IT professionals community at no cost</h1>
            </div>

            <div className='h-max w-[400px] max-w-full m-1 p-2 flex justify-center items-start flex-col flex-nowrap gap-3'>

            </div>

        </div>

        <div className='h-max w-full max-w-full m-1 px-2 pt-4 font-mulish text-base md:text-sm sm:text-xs text-center flex justify-center items-center flex-col flex-nowrap gap-2'>
            <h1>© 2024 AceExams doesn't offer Real Microsoft, AWS or Cisco Exam Questions.</h1>
            <h1>AceExams is <b>not</b> affiliated or certified by any certification provider.</h1>
        </div>

    </div>
  )
}

export default Footer

//<div className='h-max w-[400px] max-w-full m-1 p-2 flex justify-center items-start flex-col flex-nowrap gap-3'>
//                <h1 className='h-max w-max max-w-full m-1 py-1 font-mulish font-medium text-base sm:text-sm border-b-2 border-[#0095EB]'>SITEMAP</h1>
//                <div className='h-max w-[250px] max-w-full m-1 p-1 font-mulish text-base md:text-sm sm:text-xs flex justify-between items-start flex-row flex-wrap'>
//                    <h1 className='h-max w-[120px]'>&gt; &nbsp; Home</h1>
//                    <h1 className='h-max w-[120px]'>&gt; &nbsp; All Exams</h1>
//                    <h1 className='h-max w-[120px]'>&gt; &nbsp; News</h1>
//                    <h1 className='h-max w-[120px]'>&gt; &nbsp; About</h1>
//                    <h1 className='h-max w-[120px]'>&gt; &nbsp; Contact</h1>
//                    <h1 className='h-max w-[120px]'>&gt; &nbsp; Forum</h1>
//                    <h1 className='h-max w-[120px]'>&gt; &nbsp; DMCA</h1>
//                    <h1 className='h-max w-[120px]'>&gt; &nbsp; Login</h1>
//                    <h1 className='h-max w-[120px]'>&gt; &nbsp; Sign up</h1>
//                    <h1 className='h-max w-[120px]'>&gt; &nbsp; Terms & Privacy Policy</h1>
//                </div>
//            </div>
