import { createContext} from "react";
import Amazon from '../images/amazon.png'
import Cisco from '../images/cisco.png'
import Microsoft from '../images/microsoft.png'
import Comptia from '../images/comptia.png'
import Isaca from '../images/isaca.png'
import Vmware from '../images/vmware.png'
import Salesforce from '../images/salesforce.png'
import Google from '../images/google.png'
import Oracle from '../images/oracle.png'
import Isc from '../images/isc.png'

// import the que like this
import cloudPractitioner from './AWS/AWS Certified Cloud Practitioner - CLF-C02'
import developersAssociate from './AWS/AWS Certified Developers Associate - DVA-C02'


export const AppContext = createContext();

export default function AppContextProvider({children}){

    const data = [
        {
            id: 1,
            title: 'Amazon',
            img: Amazon,
            links: [
                 {
                     name: 'AWS Certified Cloud Practitioner - CLF-C02',
                     url: '/amazon/aws-certified-cloud-practitioner-clf-c02',
                     mcq: cloudPractitioner,
                 },
                { 
                    name: 'AWS Certified Developers Associate - DVA-C02', 
                    url: '/amazon/aws-certified-developers-associate-dva-c02',
                    mcq: developersAssociate,
                },
//                 {
//                     name: 'AWS Certified Solutions Architect - Professional SAP-C02',
//                     url: '/amazon/aws-certified-solutions-architect-sap-c02',
//                     mcq: [
//
//                     ],
//                 }
            ]
        },
    
//        {
//            id: 2,
//            title: 'Cisco',
//            img: Cisco,
//            links: [
//             {
//                 name: '200-201: Understanding Cisco Cybersecurity Operations Fundamentals (CBROPS)',
//                 url: '/cisco/200-201',
//                 mcq: [
//
//                 ],
//             },
//             {
//                 name: '200-301: Cisco Certified Network Associate (CCNA)',
//                 url: '/cisco/200-301',
//                 mcq: [
//
//                 ],
//             },
//             {
//                 name: '200-901: DevNet Associate (DEVASC)',
//                 url: '/cisco/200-901',
//                 mcq: [
//
//                 ],
//             }
//            ]
//        },
//
//        {
//            id: 3,
//            title: 'Microsoft',
//            img: Microsoft,
//            links: [
//             {
//                 name: 'AI-102: Designing and Implementing a Microsoft Azure AI Solution',
//                 url: '/microsoft/ai-102',
//                 mcq: [
//
//                 ],
//             },
//             {
//                 name: 'AI-900: Microsoft Azure AI Fundamentals',
//                 url: '/microsoft/ai-900',
//                 mcq: [
//
//                 ],
//             },
//             {
//                 name: 'AZ-104: Microsoft Azure Administrator',
//                 url: '/microsoft/az-104',
//                 mcq: [
//
//                 ],
//             }
//            ]
//        },
//
//        {
//            id: 4,
//            title: 'CompTIA',
//            img: Comptia,
//            links: [
//             {
//                 name: '220-1101: CompTIA A+ Certification Exam: Core 1',
//                 url: '/comptia/220-1101',
//                 mcq: [
//
//                 ],
//             },
//             {
//                 name: '220-1102: CompTIA A+ Certification Exam: Core 2',
//                 url: '/comptia/220-1102',
//                 mcq: [
//
//                 ],
//             },
//             {
//                 name: 'CAS-004: CompTIA Advanced Security Practitioner (CASP+) CAS-004',
//                 url: '/comptia/cas-004',
//                 mcq: [
//
//                 ],
//             }
//            ]
//        },
//
//        {
//            id: 5,
//            title: 'ISACA',
//            img: Isaca,
//            links: [
//             {
//                 name: 'CISA: Certified Information Systems Auditor',
//                 url: '/isaca/cisa',
//                 mcq: [
//
//                 ],
//             },
//             {
//                 name: 'CISM: Certified Information Security Manager',
//                 url: '/cisco/cism',
//                 mcq: [
//
//                 ],
//             },
//             {
//                 name: 'CRISC: Certified in Risk and Information Systems Control',
//                 url: '/cisco/crisc',
//                 mcq: [
//
//                 ],
//             }
//            ]
//        },
//
//        {
//            id: 6,
//            title: 'VMWare',
//            img: Vmware,
//            links: [
//
//            ]
//        },
//
//        {
//            id: 7,
//            title: 'Salesforce',
//            img: Salesforce,
//            links: [
//
//            ]
//        },
//
//        {
//            id: 8,
//            title: 'Google',
//            img: Google,
//            links: [
//
//            ]
//        },
//
//        {
//            id: 9,
//            title: 'Oracle',
//            img: Oracle,
//            links: [
//
//            ]
//        },
//
//        {
//            id: 10,
//            title: 'ISC',
//            img: Isc,
//            links: [
//
//            ]
//        },
        
    ];

    const value = {data};

    return <AppContext.Provider value={value}>
        {children}
    </AppContext.Provider>
}